import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { fromEvent, merge, Observable, Subscription } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

/**
 * Global map to hold all slot elements as observables, the host element is the namespace of the shadow subtree that the slot element is attached to, and the nested map contains a reference to the original slot element's slotchange event target element.
 */
export const slotMap = new Map<Element, Map<string, Observable<HTMLSlotElement>>>();

/**
 * This directive creates copies of a slot's assigned elements and inserts those after all other slot element with the same reference.
 *
 * By design, the Shadow DOM will only insert an assigned element once and then destroys all other slot elements when the first instance has been determent. This is a problem when repeating a slot, for example in an `*ngFor` loop where only the first instance will be assigned. This directive solves this issue.
 *
 * **isStatic**
 *
 * For performance reasons, slot elements are only assigned once by default. However, if an element needs to be updated after the initial assignment you can make it none-static, meaning that the slots will be reassigned on every `slotchange` event.
 *
 * To update the slot on every `slotchange` event, set the `isStatic` property to false.
 *
 * ```html
 * <slot [isStatic]="false">
 * ```
 */
@Directive({
  selector: "slot",
})
export class SlotDirective implements AfterViewInit, OnDestroy {
  /**
   * Value of the slot element's `name` attribute. Empty values are possible.
   */
  @Input() name = "";
  /**
   * Set to `false` if slot value is expected to change after first render. Default is `true` for performance reasons.
   */
  @Input() isStatic = true;

  private slotchangeSubscription!: Subscription;

  constructor(private elementRef: ElementRef<HTMLSlotElement>, private sanitizer: DomSanitizer) {}

  ngAfterViewInit() {
    this.slotchangeSubscription = this.onSlotchange().subscribe((slotElement) => {
      // Ignore the original slot element
      if (!slotElement.isSameNode(this.elementRef.nativeElement)) {
        // Clear the native element of its previous value
        this.elementRef.nativeElement.innerHTML = "";
        // Iterate each assigned slottable element clone
        for (const assignedNode of slotElement.assignedNodes().map((assignedNode) => assignedNode.cloneNode(true))) {
          // Sanitize the projected markup as HTML elements or text nodes
          if (assignedNode instanceof Element) {
            // Remove the slot attribute to avoid a DOM sanitizer warnings
            assignedNode.removeAttribute("slot");
            this.elementRef.nativeElement.innerHTML += this.sanitizer.sanitize(SecurityContext.HTML, assignedNode.outerHTML);
          }
          if (assignedNode instanceof Text)
            this.elementRef.nativeElement.innerHTML += this.sanitizer.sanitize(SecurityContext.HTML, assignedNode.textContent);
        }
      }
      // Kill the subscription immediately after the initial slot duplication for static slot elements,
      // placed last in callstack to make the event stream asynchronous so that it can be unsubscribed
      // from within the subscription
      setTimeout(() => {
        if (this.isStatic) this.ngOnDestroy();
      });
    });
  }

  ngOnDestroy() {
    if (!this.slotchangeSubscription.closed) this.slotchangeSubscription.unsubscribe();
  }

  /**
   * Returns an observable that emits on to the original slot element's slotchange event and that is scoped to the shadow root subtree that this slot element is attached to.
   */
  private onSlotchange() {
    // The host element of the shadow subtree that this slot element belongs to
    const hostElement = (this.elementRef.nativeElement.getRootNode() as ShadowRoot).host;
    // This is the first slot element of this host
    if (!slotMap.get(hostElement))
      slotMap.set(hostElement, new Map([[this.name, this.getSlotElementSlotchangeMutationObservable()]]));
    // This is the first slot in the template markup with this this name
    else if (!slotMap.get(hostElement)?.has(this.name))
      slotMap.get(hostElement)?.set(this.name, this.getSlotElementSlotchangeMutationObservable());
    // Return observable for this slot element scoped to the host element namespace
    return slotMap.get(hostElement)?.get(this.name) as Observable<HTMLSlotElement>;
  }

  /**
   * Returns an observable for the current slot element that emits when new slots elements are created or removed or a change is observed from the slottable element and/or its children.
   */
  private getSlotElementSlotchangeMutationObservable() {
    // Emits on changes to the slottable element and/or its children
    const slotMutationObservable = new Observable<HTMLSlotElement>((observer) => {
      const mutation = new MutationObserver(() => {
        observer.next(this.elementRef.nativeElement);
      });
      for (const assignedNode of this.elementRef.nativeElement.assignedNodes())
        mutation.observe(assignedNode, {
          attributes: true,
          childList: true,
          subtree: true,
          characterData: true,
        });
      const unsubscribe = () => {
        mutation.disconnect();
      };
      return unsubscribe;
    });
    // Emits when slot elements are added/removed in the template markup
    // or when the slottable element attributes change
    const slotchangeObservable = fromEvent(this.elementRef.nativeElement, "slotchange");
    // Merge slotchange event and mutation observer into a single event stream
    return merge(slotMutationObservable, slotchangeObservable).pipe(
      // Start emitting stream using the original slot element
      startWith(this.elementRef.nativeElement),
      // Pluck the event target element on subsequent slotchange events
      map((event) => ("target" in event ? event.target : event) as HTMLSlotElement),
      // Ensure that the slot element has assigned nodes before proceeding
      filter((slotElement) => slotElement.assignedNodes().length !== 0)
    );
  }
}
