/* eslint-disable @typescript-eslint/no-explicit-any */

const DEFAULT_DEBOUNCE_DURATION = 250;

/**
 * Delays a method its execution a certain amount of milliseconds after the first call was invoked.
 *
 * This decorator is typically used if a method is called a lot of times and you want to avoid performance problems, for example when attaching event listeners to scroll or window resize events.
 *
 * @param delay Debounce time expressed in milliseconds.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Debounce(delay = DEFAULT_DEBOUNCE_DURATION): MethodDecorator {
  return function (_: unknown, propertyKey: PropertyKey, descriptor: PropertyDescriptor) {
    // Attaching the value on a private property of this ensures that
    // it can be set on the class instance as oppose to the prototype
    // Casting as string due to an issue in TypeScript: https://github.com/microsoft/TypeScript/issues/1863
    const symbol = Symbol("__Debounce__" + String(propertyKey)) as unknown as keyof { value: unknown };
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      clearTimeout(this[symbol]);
      this[symbol] = setTimeout(() => original.apply(this, args), delay);
    };
    return descriptor;
  };
}
