import { registerLocaleData } from "@angular/common";
import {
  Compiler,
  ComponentFactoryResolver,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  InjectionToken,
  Injector,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  NgModuleFactory,
  NgModuleRef,
  Optional,
  SkipSelf,
  StaticProvider,
  Type,
} from "@angular/core";

/**
 * `Environment` configuration object's injection token.
 *
 * Used for injecting the environment configuration object in a component, directive, service or class.
 *
 * The following example injects the environment token in a class.
 *
 * ```typescript
 * class Example {
 *  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {}
 * }
 * ```
 */
export const ENVIRONMENT = new InjectionToken<Environment>("environment");

/**
 * List of locales supported by this application
 */
export const ENVIRONMENT_LOCALES = <const>["sv-SE", "sv-FI", "fi-FI"];

/**
 * List of regions supported by this application
 */
export const ENVIRONMENT_REGIONS = <const>["se", "fi"];

/**
 * List of languages supported by this application
 */
export const ENVIRONMENT_LANGUAGES = <const>["sv", "fi"];

/**
 * The expected errors that could happen as a result of using this package
 */
export enum Errors {
  Undefined = "No application configuration object was passed to the constructor. To solve this, make sure that the Vattenfall class is instantiated with proper runtime configurations.",
  Locale = "The provided locale is either empty or not supported. Please use one of the supported locales: 'sv-SE', 'sv-FI' or 'fi-FI'.",
  BundleURL = "The provided bundle URL is empty.",
  BundleVersion = "The bundle version is empty.",
  ChatBaseUrl = "Vattenfall Elements. The Chat API base URL is missing. Elements depending on this endpoint will not work.",
  FinlandBaseUrl = "Vattenfall Elements. The Finland base URL is missing. Elements depending on this endpoint will not work.",
  SelfServiceBaseUrl = "Vattenfall Elements. The Self Service API base URL is missing. Elements depending on this endpoint will not work.",
  Initialized = "Application already initialized. There should only be one instance of Vattenfall Elements, please make sure the it is not called more than once.",
  Exception = "Vattenfall Elements. Application was not bootstrapped.",
  StaticProvidersCallback = "No static providers callback. Make sure that a platform browser dynamic callback has been passed in 'main.ts' when calling 'createElementsClass'.",
  ModuleImported = "Could not import 'ElementsModule'. This module has already been imported, please sure it is imported once and in the application root module.",
  ElementDefined = "This custom element has already been defined. This suggest that 1) there might be another custom element with the same name existing on this site, or 2) the 'customElements.define' method might be called twice for the same element. Custom elements should be defined using the 'ElementsModule'.",
  BootstrapElement = "No bootstrapping element. Make sure that a custom element has been defined in the module's bootstrap array.",
  LoadModule = "Could not import module from dynamic path. Make sure that the custom elements module has been properly declared in the ElementsModule using dynamic import syntax.",
  NoModule = "Could not load module. Make sure that a module has been declared either as a dynamic import using the 'loadChildren' property or as a static import using the 'elementModule' property.",
  CompileModule = "Could not compile module. Make sure that all libraries are built using the same view engine. Re-build all applications and libraries in the workspace and try again.",
  ElementNotFound = "Could not compile module. No element was found with this tag.",
  CustomElement = "Could not create custom element from component.",
  LocaleDataFailed = "Could not load locale data.",
}

/**
 * The global application configurations
 */
export interface AppConfig {
  /**
   * Whether the application has been initialized or not
   */
  initialized: boolean;
  /**
   * Whether the application is running in livemode or not. Default is `true`.
   */
  livemode: boolean;
  /**
   * Callback function to be invoked once the application static providers has been resolved
   */
  onStaticProvidersResolved?: OnStaticProvidersResolved<unknown>;
}

/**
 * Global application details, such as whether the application has been initialized or not and the path to the app module.
 */
export const app: AppConfig = {
  livemode: true,
  initialized: false,
};

/**
 * The type describing supported locales for this application
 */
export type Locale = typeof ENVIRONMENT_LOCALES[number];

/**
 * The type describing supported regions for this application
 */
export type Region = typeof ENVIRONMENT_REGIONS[number];

/**
 * The type describing supported languages for this application
 */
export type Language = typeof ENVIRONMENT_LANGUAGES[number];

/**
 * The type describing backend server endpoints. `null` indicates that the endpoint is not supported in that specific server environment.
 */
export type Endpoints = {
  selfServiceApi: string | null;
  finlandApi: string | null;
  chatApi: string | null;
};

/**
 * The type describing a callback function to be invoked once the application static providers has been resolved
 */
export type OnStaticProvidersResolved<T> = (staticProviders: StaticProvider[]) => Promise<NgModuleRef<T>>;

/**
 * The type describing a callback function to be invoked once the application bootstrapping completed without exception
 */
export type OnSuccess = () => void;

/**
 * The type describing a callback function to be invoked once the application bootstrapping failed with exception
 */
export type OnError = (error: Error) => void;

/**
 * This is the interface describing an application bootstrap configuration object
 */
export interface Config {
  /**
   * The user's selected locale represented as a BCP 47 language and region tag.
   */
  locale: Locale;
  /**
   * Whether the application is running in livemode or not. Default is `true`.
   */
  livemode?: boolean;
  /**
   * The base URL pointing to the endpoints used by the Chat API. Only applicable when serving the application in `livemode`.
   */
  chatBaseUrl?: string;
  /**
   * The base URL pointing to the endpoints used by the Finland website. Only applicable when serving the application in `livemode`.
   */
  finlandBaseUrl?: string;
  /**
   * The base URL pointing to the endpoints used by the Self-Service API. Only applicable when serving the application in `livemode`.
   */
  selfServiceBaseUrl?: string;
  /**
   * The URL host to where the static files should be loaded. Only applicable when serving the application in `livemode`.
   */
  bundleUrl?: string;
  /**
   * The specific bundle version that should be used to instantiate the application. Only applicable when serving the application in `livemode`.
   */
  bundleVersion?: string;
  /**
   * The tracking id for Google Analytics.
   */
  trackingId?: string;
  /**
   * The reporting view id for Google Analytics.
   */
  viewId?: string;
}

/**
 * This is the interface describing an environment configuration object
 */
export interface Environment extends Config {
  /**
   * All the server endpoints used for this application and server environment.
   */
  endpoints: Endpoints;
  /**
   * The user's selected locale language.
   */
  language: Language;
  /**
   * Whether the application is running in livemode or not
   */
  livemode: boolean;
  /**
   * The user's selected locale region.
   */
  region: Region;
}

/**
 * Sets the global application bootstrap configurations and returns the `Elements` class.
 * @param onStaticProvidersResolved The callback function to be invoked with the static provides from the Elements class
 */
export function createElementsClass<T>(onStaticProvidersResolved: OnStaticProvidersResolved<T>, livemode = true) {
  app.onStaticProvidersResolved = onStaticProvidersResolved;
  app.livemode = livemode;
  return Elements;
}

/**
 * Resets the global application bootstrap configurations and returns the `Elements` class.
 * @param onStaticProvidersResolved The callback function to be invoked with the static provides from the Elements class
 */
export function resetElementsClass<T>(onStaticProvidersResolved?: OnStaticProvidersResolved<T>, livemode = true) {
  app.initialized = false;
  app.livemode = livemode;
  app.onStaticProvidersResolved = onStaticProvidersResolved;
  return Elements;
}

/**
 * `Elements` is the class responsible for bootstrapping instances of a Vattenfall Elements application from an environment configuration object.
 *
 * The environment configuration object is a set of runtime instructions required to bootstrap the application, such as the user's preferred locale or properties consumed by external data tracking services.
 * Optionally, callback functions can be passed to the constructor which will be called if the application bootstrapping was successful or not.
 *
 * **Usage**
 *
 * ```html
 * <script type="module">
 *  // Creates a instance of an Vattenfall Elements application serving
 *  // Swedish speaking users on localhost
 *  new Elements({
 *    livemode: true,
 *    locale: "sv-SE",
 *    trackingId: "UA-4663340-1",
 *    viewId: "180753764",
 *    finlandBaseUrl: "https://vattenfallfi-fn-tst.azurewebsites.net",
 *    selfServiceBaseUrl: "https://selfserviceapi.test.vattenfall.fi"
 *  })
 * </script>
 * ```
 */
export class Elements {
  /**
   * @param config The application bootstrap `Config` object
   * @param onSuccess An optional callback function be called if the application bootstrapping was successful
   * @param onError An optional callback function be called if the application bootstrapping failed with exception
   */
  constructor(private config: Config, private onSuccess?: OnSuccess, private onError?: OnError) {
    this.bootstrap(this.config)
      .then(() => {
        if (this.onSuccess) this.onSuccess();
        app.initialized = true;
      })
      .catch((error) => {
        console.error(Errors.Exception, error);
        if (this.onError) this.onError(error);
      });
  }

  /**
   * Performs a validity check of the provided environment object to make sure it contains all required properties and values. An invalid environment object will result in error.
   * @param environment the `Environment` configuration object
   */
  static validate(config: Config) {
    if (app.initialized) throw new Error(Errors.Initialized);
    if (!config || !Object.keys(config).length) throw new Error(Errors.Undefined);
    if (!config.locale || !ENVIRONMENT_LOCALES.includes(config.locale)) throw new Error(Errors.Locale);
    if (!config.bundleUrl && app.livemode && config.livemode !== false) throw new Error(Errors.BundleURL);
    if (!config.bundleVersion && app.livemode && config.livemode !== false) throw new Error(Errors.BundleVersion);
    if (!app.onStaticProvidersResolved) throw new Error(Errors.StaticProvidersCallback);
    if (!config.chatBaseUrl && app.livemode && config.livemode !== false) console.warn(Errors.ChatBaseUrl);
    if (!config.finlandBaseUrl && app.livemode && config.livemode !== false) console.warn(Errors.FinlandBaseUrl);
    if (!config.selfServiceBaseUrl && app.livemode && config.livemode !== false) console.warn(Errors.SelfServiceBaseUrl);
  }

  /**
   * Sets the base path from where static resources should be loaded, this is important when resources are loaded from an external endpoint like a CDN.
   * @param config The `Config` object
   */
  static publicPath(config: Config) {
    if (app.livemode && config.livemode !== false) {
      // The __webpack_public_path__ is a special configuration option that allows you to specify a base path
      // from where the application should load its static assets
      // @ts-ignore Global webpack property that is not recognized by typescript compiler
      __webpack_public_path__ = `${config.bundleUrl}/${config.bundleVersion}/`;
    }
  }

  /**
   * Takes a locale configuration and lazy-loads its Angular locale data file from the relevant path.
   * @param locale Locale string
   */
  static async locale(locale: Locale) {
    let localeData;
    try {
      switch (locale) {
        case "sv-SE":
          localeData = await import("@angular/common/locales/sv").then((module) => module.default);
          break;
        case "sv-FI":
          localeData = await import("@angular/common/locales/sv-FI").then((module) => module.default);
          break;
        case "fi-FI":
          localeData = await import("@angular/common/locales/fi").then((module) => module.default);
          break;
      }
      return localeData;
    } catch (error) {
      throw new Error(`${Errors.LocaleDataFailed} Locale: ${locale}.\n\nException: ${error}`);
    }
  }

  /**
   * Returns the backend environment configurations from API
   * @param config the `Config` configuration object
   */
  static environment(config: Config) {
    return {
      livemode: app.livemode && config.livemode !== false,
      locale: config.locale,
      language: config.locale.split("-")[0].toLowerCase(),
      region: config.locale.split("-")[1].toLowerCase(),
      trackingId: config.trackingId,
      viewId: config.viewId,
      endpoints: {
        selfServiceApi: config.selfServiceBaseUrl ?? "",
        finlandApi: config.finlandBaseUrl ?? "",
        chatApi: config.chatBaseUrl ?? "",
      },
    } as Environment;
  }

  /**
   * Tries to boostraps the application from the provided environment configuration object, and throws an error if unsuccessful.
   * @param config the `Config` configuration object
   */
  private async bootstrap(config: Config) {
    Elements.validate(config);
    Elements.publicPath(config);

    const environment = Elements.environment(config);
    const localeData = await Elements.locale(config.locale);

    registerLocaleData(localeData, config.locale);

    if (app.onStaticProvidersResolved)
      return app.onStaticProvidersResolved([
        {
          provide: ENVIRONMENT,
          useValue: environment as Environment,
        },
        // TODO (stefan): Temporarily providing the elements environment configs as a string based
        // injection token. This is because we are referencing it from the @vattenfall/self-service library and
        // which cannot import files from this directory.
        // Once we have the @vattenfall/core library setup this will no longer be an issue.
        {
          provide: "ELEMENTS_ENVIRONMENT",
          useValue: environment as Environment,
        },
        {
          provide: LOCALE_ID,
          useValue: config.locale,
        },
      ]);
    return undefined;
  }
}

/**
 * `ElementModules` arrays's injection token. Used for injecting the components array in the `ElementsModule`
 */
export const ELEMENT_ROUTES = new InjectionToken<ElementRoutes>("ELEMENT_ROUTES");

/**
 * Represents an array of Vattenfall Elements routes or components.
 */
export type ElementRoutes = Array<ElementRoute | DepreciatedComponentType>;

/**
 * Represents a component type class
 * @depreciated The preferred way is using an `ElementRoute` object to make use of features such as lazy-loading.
 */
export type DepreciatedComponentType = Type<unknown>;

/**
 * A configuration object that defines a single element. A set of elements are collected in a `Elements` array.
 */
export interface ElementRoute {
  /**
   * The element's tag name.
   */
  tagName: string;
  /**
   * The element to instantiate when the tag name matches. Should be empty if a `loadElement` function has been specified.
   *
   * When specified, the element will be included in the application bootstrapping bundle, when not a dynamic loading callback should be specified for `loadElementModule` in which case it will be lazy loaded when requested in the DOM.
   */
  elementModule?: Type<unknown>;
  /**
   * A `LoadElementModuleCallback` object specifying lazy-loaded child routes.
   */
  loadChildren?: LoadElementModuleCallback;
}

/**
 * A function that is called to resolve a collection of lazy-loaded elements.
 *
 * This function must be implemented using an ES dynamic `import()` expression. For example: `import('./example/example.module').then(mod => mod.ExampleElementModule)`
 */
export type LoadElementModuleCallback = () => Promise<Type<unknown>>;

/**
 * `ElementsModule` manages the application `Element` components, and should be imported once at the application root.
 *
 * To create module use the `forRoot` static method, it will create a module that contain all the elements and instantiation logic required to load and generate elements.
 *
 * The module should be imported as follows: `imports: [ ElementsModule.forRoot(ELEMENT_ROUTES) ]` in your application's root module.
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementsModule {
  static forRoot(elementRoutes: ElementRoutes): ModuleWithProviders<ElementsModule> {
    return {
      ngModule: ElementsRootModule,
      providers: [
        {
          provide: ELEMENT_ROUTES,
          useValue: elementRoutes,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ElementsModule) {
    // Make sure multiple instances of the environment module is not created
    // The elements module should only be imported once at the application root
    if (parentModule) {
      throw new Error(Errors.ModuleImported);
    }
  }
}

/**
 * `ElementsRootModule` is the module return from the `ElementModule.forRoot` method. It is responsible for creating custom elements from a list of `ElementRoutes`.
 */
@NgModule()
export class ElementsRootModule {
  /**
   * Creates a custom element from an Angular component and register it in the browser custom elements registry
   * @param tagName Name for the new custom element. Note that custom element names must contain a hyphen.
   * @param component The Angular component to create a custom element from
   */
  public async createElement(tagName: string, component: Type<unknown>) {
    if (!customElements.get(tagName)) {
      try {
        const customElement = await import("@angular/elements").then((angularElementsModule) =>
          angularElementsModule.createCustomElement(component, {
            injector: this.injector,
          })
        );
        customElements.define(tagName, customElement);
      } catch (error) {
        throw new Error(`${Errors.CustomElement} Failed with exception: ${(error as Error).message || error}`);
      }
      return customElements.whenDefined(tagName);
    } else throw new Error(Errors.ElementDefined);
  }

  /**
   * Creates a mutation observer from `ElementRoute` that listens to inclusions of a specific custom element tag in the DOM subtree and compiles components when found
   * @param elementRoute
   */
  private observeElement(elementRoute: ElementRoute) {
    const mutationObserver = new MutationObserver(async () => {
      if (document.body.querySelector(elementRoute.tagName)) {
        try {
          await this.compileElements(elementRoute);
        } finally {
          // Make sure to disconnect on failed attempts to avoid an endless loop
          mutationObserver.disconnect();
        }
      }
    });
    mutationObserver.observe(document.body, {
      subtree: true,
      childList: true,
    });
  }

  /**
   * Compiles a elements from `ElementRoute` module and add class to custom element when compiling or failing
   * @param elementRoute The `ElementRoute` object
   */
  private async compileElements(elementRoute: ElementRoute) {
    const customElement = document.querySelector(elementRoute.tagName);
    if (!customElement) throw new Error();
    customElement.classList.add("vattenfall-element");
    try {
      // Add compiling class
      const elementModule = await this.getElementModule(elementRoute);
      const elementModuleFactory = await this.getElementModuleFactory(elementModule);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const elementModuleRef = elementModuleFactory!.create(this.injector) as unknown as {
        _bootstrapComponents: Type<unknown>[];
      };
      const elementComponentTypes = elementModuleRef._bootstrapComponents;
      if (!elementComponentTypes.length) throw new Error(Errors.BootstrapElement);
      else
        for (const elementComponentType of elementComponentTypes)
          await this.createElement(elementRoute.tagName, elementComponentType);
      // Remove compiling class
      customElement.classList.add("completed");
    } catch (error) {
      // Add error class
      customElement.classList.add("failed");
      if (!this.environment.livemode)
        customElement.innerHTML = `<code><strong>Vattenfall Element</strong><br><br>${error}</code>`;
    }
  }

  /**
   * Loads element module from `ElementRoute` and throw an error if unsuccessful
   * @param elementRoute The `ElementRoute` object
   */
  private async getElementModule(elementRoute: ElementRoute) {
    try {
      if (elementRoute.loadChildren) return await elementRoute.loadChildren();
    } catch (error) {
      throw new Error(`${Errors.LoadModule} Failed with exception: ${(error as Error).message || error}`);
    }
    if (!elementRoute.elementModule) throw new Error(Errors.NoModule);
    return elementRoute.elementModule;
  }

  /**
   * Compiles element module from `ElementRoute` and throw an error if unsuccessful
   * @param elementRoute The `ElementRoute` object
   */
  private async getElementModuleFactory(elementModule: Type<unknown>) {
    try {
      if (elementModule instanceof NgModuleFactory) return elementModule;
      else {
        return await this.compiler.compileModuleAsync(elementModule);
      }
    } catch (error) {
      throw new Error(`${Errors.CompileModule} Failed with exception: ${(error as Error).message || error}`);
      return;
    }
  }

  constructor(
    private injector: Injector,
    private compiler: Compiler,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Optional()
    @Inject(ELEMENT_ROUTES)
    public elementRoutes: ElementRoutes,
    @Optional()
    @Inject(ENVIRONMENT)
    public environment: Environment
  ) {
    // The provided route is a proper element route
    for (const elementRoute of elementRoutes)
      if ("tagName" in elementRoute)
        if (document.body.querySelector(elementRoute.tagName)) this.compileElements(elementRoute);
        else this.observeElement(elementRoute);
      // The provided route is a depreciated component class
      else {
        const tagName = this.componentFactoryResolver.resolveComponentFactory(elementRoute).selector;
        this.createElement(tagName, elementRoute);
      }
  }
}
