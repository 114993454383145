import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";

import { DateRangePipe } from "./date-range.pipe";
import { MonthPipe } from "./month.pipe";
import { PickAllPipe } from "./pick-all.pipe";
import { RelativeTimeFormatPipe } from "./relative-time-format.pipe";
import { UnitPipe } from "./unit.pipe";

@NgModule({
  declarations: [UnitPipe, DateRangePipe, RelativeTimeFormatPipe, PickAllPipe, MonthPipe],
  imports: [CommonModule],
  exports: [UnitPipe, DateRangePipe, RelativeTimeFormatPipe, PickAllPipe, DatePipe, MonthPipe],
  providers: [DecimalPipe, DatePipe],
})
export class PipesModule {}
