import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { isNullable } from "@vattenfall/util";
import { Observable, of, throwError } from "rxjs";
import { delay } from "rxjs/operators";

/**
 * The default service response delay time expressed in milliseconds
 */
const SERVICE_DELAY_TIME = 500;

/**
 * TODO (stefan): Check if and where this is referenced, if not then it could be removed.
 * Simulate HTTP response
 * @param status HTTP response status code
 * @param body HTTP response body
 * @return HTTP response
 */
export function httpResponseSimulation<T>(status: number, body?: T) {
  if (status >= 200 && status <= 300)
    return of(new HttpResponse({ status, body })).pipe(delay(SERVICE_DELAY_TIME)) as unknown as Observable<HttpEvent<T>>;
  else
    return throwError(new HttpErrorResponse({ status })).pipe(delay(SERVICE_DELAY_TIME)) as unknown as Observable<HttpEvent<T>>;
}

/**
 * Mockup object as passed to `MockupHttpInterceptor`
 */
export interface Mockup<T> {
  /**
   * Base endpoint URL path (should start with '/'), may contain parameters, i.e. `:premiseId` and query parameters i.e. `someParam=someValue`.
   */
  pathMatcher: string;
  /**
   * Delay response time in milliseconds
   */
  delay: number;
  /**
   * Array of data objects showing different possible variations of mockup data
   */
  data: Array<T>;
  /**
   * Limits the size of the returned list of data in case multiple items are found in a single request.
   */
  limit?: number;
}

/**
 * HTTP status code
 */
export enum HttpStatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  MethodNotAllowed = 422,
  ServerError = 500,
}

/**
 * The request parameters represented as a key-value object
 */
export type Params = { [param: string]: string };

/**
 * Intercepts requests and returns mockup data as response.
 *
 * You may override the `onGet`, `onGetAll`, `onPost`, `onPut` and `onDelete` request handlers in your child class to better match the actual endpoint behaviour.
 *
 * This will override the `onDelete` method and return `422 Method Not Allowed` on all requests made to this endpoint with the method `DELETE`:
 *
 * ```typescript
 * onDelete() {
 *   return super.httpResponse(HttpStatusCode.MethodNotAllowed)
 * }
 * ```
 */
export class MockupHttpInterceptor<T> implements HttpInterceptor {
  constructor(public mockup: Mockup<T>) {
    if (!this.mockup.pathMatcher)
      throw new Error(
        `[ Vattenfall Elements ] Could not initialize 'MockupHttpInterceptor'. 'pathMatcher' property is undefined.`
      );
    if (!this.mockup.pathMatcher.startsWith("/"))
      throw new Error(
        `[ Vattenfall Elements ] Could not initialize 'MockupHttpInterceptor'. 'pathMatcher' property needs to start with '/'.`
      );
    if (!this.mockup.data.length)
      throw new Error(`[ Vattenfall Elements ] Could not initialize 'MockupHttpInterceptor'. 'data' array is empty.`);
  }

  /**
   * Intercept HTTP request and return appropriate HTTP response
   * @param request HTTP request object
   */
  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const { method, url, body } = request;
    if (!this.matchUrlPath(this.mockup.pathMatcher, url)) return next.handle(request);

    const params = this.parseParams(this.mockup.pathMatcher, url);

    switch (method) {
      case "GET": {
        return this.onGet(params);
      }
      case "POST": {
        return this.onPost(body);
      }
      case "PUT": {
        return this.onPut(body, params);
      }
      case "DELETE": {
        return this.onDelete(params);
      }
      // Method Not Allowed
      default:
        return this.httpResponse(HttpStatusCode.MethodNotAllowed);
    }
  }

  /**
   * Simulate HTTP response
   * @param status HTTP response status code
   * @param body HTTP response body
   */
  protected httpResponse(status: number, body?: T | Array<T> | null) {
    if (status >= 200 && status <= 300)
      return of(new HttpResponse({ status, body })).pipe(delay(this.mockup.delay)) as Observable<HttpEvent<T>>;
    else {
      return throwError(
        new HttpErrorResponse({
          error: `Error ${status}`,
          status,
        })
      ).pipe(delay(this.mockup.delay)) as Observable<HttpEvent<T>>;
    }
  }

  /**
   * Get request handler
   * @param param Parameter holding the key and value to query the data.
   */
  protected onGet(params?: Params) {
    const payload = this.mockup.data.filter(this.matchIdentifier(params));
    if (payload.length === 1 || this.mockup.limit === 1) return this.httpResponse(HttpStatusCode.Success, payload[0]);
    else if (payload.length > 1)
      return this.httpResponse(HttpStatusCode.Success, payload.slice(0, this.mockup.limit || payload.length));
    else return this.httpResponse(HttpStatusCode.NotFound, payload);
  }

  /**
   * Post request handler
   * @param payload the payload to update the item.
   */
  protected onPost(payload: T | null) {
    if (isNullable(payload)) return this.httpResponse(HttpStatusCode.BadRequest, payload);
    else {
      // Casting as T since TypeScript compiler doesn't seem to understand 'isNullable'
      this.mockup.data = [...this.mockup.data, payload as T];
      return this.httpResponse(HttpStatusCode.Success, payload);
    }
  }

  /**
   * Put request handler
   * @param param key, value parameter to query the data
   * @param payload the payload to update the item.
   */
  protected onPut(payload?: T | null, params?: Params) {
    if (isNullable(payload) || isNullable(params)) return this.httpResponse(HttpStatusCode.BadRequest, payload);
    else if (this.mockup.data.some(this.matchIdentifier(params))) {
      this.mockup.data = this.mockup.data.map((item) =>
        this.matchIdentifier(params)(item) ? Object.assign(item as Record<string, unknown>, payload) : item
      );
      return this.httpResponse(HttpStatusCode.Success, payload);
    } else return this.httpResponse(HttpStatusCode.NotFound, payload);
  }

  /**
   * Delete request handler
   * @param param parameter with key, value to query and delete the item in the data.
   */
  protected onDelete(params?: Params) {
    if (this.mockup.data.some(this.matchIdentifier(params))) {
      this.mockup.data = this.mockup.data.filter(this.matchIdentifier(params));
      return this.httpResponse(HttpStatusCode.Success);
    } else return this.httpResponse(HttpStatusCode.NotFound);
  }

  /**
   * Match provided resource identifiers or query parameters against data items in `mockup` array. Will match data items which does not contain properties that might be specified in `params`.
   * @param parameters
   */
  protected matchIdentifier(params: Params = {}) {
    return (item: T) =>
      Object.entries(params)
        // Testing for loose equality to match numeric values cast as strings, i.e. "123"
        .reduce(
          (match, [key, value]) =>
            !(item as Record<string, unknown>)[key] || (item as Record<string, unknown>)[key] == value ? match && true : false,
          true
        );
  }

  /**
   * Compares request URL with specified path matcher string
   * @param pathMatcher path matcher pattern to compare to the url
   * @param url url for the current request.
   */
  protected matchUrlPath(pathMatcher: string, url: string) {
    const matcherSegments = pathMatcher.split("/");
    const urlSegments = new URL(url, "https://localhost:4200").pathname.split("/");
    // Do not match URL's that have more segments than declared in pathMatcher
    if (matcherSegments.length < urlSegments.length) return false;
    // Do not match if unknown of the URL segments does not match the segments in pathMatcher
    for (let index = 0; index < matcherSegments.length; index++) {
      if (!matcherSegments[index].startsWith(":")) if (matcherSegments[index] !== urlSegments[index]) return false;
    }
    return true;
  }

  /**
   * Takes an URL and parse it against a matching string. Returns an object with the resource name or query parameter name as key and resource identifier query parameter value as value.
   * @param pathMatcher path matcher pattern to compare to the url
   * @param url url for the request
   *
   * **Example**
   *
   * This input:
   *
   * * pathMatcher: `component/:componentId`
   * * url: `component/123&queryParam=abc`
   *
   * Will give this output:
   *
   * ```
   * { componentId: "123", queryParam: "abc" }
   * ```
   */
  protected parseParams(pathMatcher: string, url: string): Params {
    const urlSegments = new URL(url, "https://localhost:4200").pathname
      .split("/")
      .map((path, index) => [new URL(pathMatcher, "https://localhost:4200").pathname.split("/")[index].replace(":", ""), path])
      .filter(([key, value]) => key !== value);

    const urlSearchParams = Array.from(new URLSearchParams(new URL(url, "https://localhost:4200").searchParams).entries());

    if (urlSegments.length || urlSearchParams.length) return Object.fromEntries([...urlSegments, ...urlSearchParams]) as Params;
    return {};
  }
}
