// TODO (stefan):
// I feel that the documentation in here is a bit verbose. I think these {@link bindingPropertyName} qwirks are great for
// public facing APIs but are less helpful when you just want to quickly understand the code.
// Also, the code provided in the examples doesn't work when using intellisense.
// I suggest we simply and fix these issues in the documentation.

/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelToDashCase, kebabToCamelCase } from "@vattenfall/util";

import { logError, isStorybookRuntime } from "../functions";

/** Storage and behavior options */
export type SlotOptions = {
  /**
   * Name of attribute selector being targeted. Specifying {@link bindingPropertyName} allows for binding to a property
   * of different name. If not specified, the name of the decorated property will be used.
   */
  bindingPropertyName?: string;
  /** Whether to access the element or access only the textContent. */
  nativeElement?: boolean;
};

/**
 * Internal representation of stored information about element and/or its textContent.
 *
 * @privateRemarks
 * When storing element ({@link SlotOptions.nativeElement} is true), both the element and its textContent is stored.
 * The reason for this is to be able to perform  non-destructive interpolation multiple times (that is keep the original,
 * work on a copy).
 *
 * When storing only the element's ({@link SlotOptions.nativeElement} is false), we could still opt to also store the
 * element itself, but it may have performance and/or memory usage implications.
 */
type SlotEntry = {
  /**
   * Optional for performance and/or memory reasons
   */
  element?: HTMLElement;
  textContent: string;
};

/**
 * @param bindingPropertyName Custom property binding name in camel or dash case.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Slot(bindingPropertyName?: string): (target: unknown, propertyKey: string) => void;

/**
 * Decorator that finds a Vattenfall Element projected node by its attribute name and returns it or its text content.
 *
 * Used for resolving projected content into component class properties without using `@ContentChild` or `@ContentChildren`,
 * however with the following limitations:
 *
 * * If multiple instances of the same custom element exist, the slotted values must be the same for all of them.
 * * Projected nodes that are modified at runtime will not be detected.
 * * Will not work with custom elements created at runtime.
 *
 * You can supply an optional name to use in templates when the component is instantiated, that maps to the name of the
 * bound property. By default, the original name of the bound property is used for input binding.
 *
 * Slot decorator also supports string interpolation. To use string interpolation, you need to wrap the property name in curly braces.
 *
 * @example TextContent extraction using interpolation
 * `<span slotIdentifierName>We found {count} matches for "{query}"</span>`
 *          Which will render to:
 *          `We found 4 matches for "value"`
 *          Given that the component (class) has the following properties: `count = 4` and `query = "value"`.
 *
 *        ```
 *          class MyClass {
 *            @Slot("slotIdentifierName") slotIdentifierName: string;
 *            count = 4;
 *            query = "value";
 *          }
 *         ```
 *
 *
 * ```
 * When running in Storybook, extracting elements from the DOM does not (currently) work. In this case, the explicit
 * setters need to be used:
 * ```
 * @example Running in Storybook and using the Slot setter
 *     `const anchorElement = document.createElement("a");
 *
 *     export const Main: Story<MyElement> = ({ ... }: MyElement) => {
 *         return {
 *             props: {
 *                 elementPropertyToBeBoundBySlot1: "explicit value to use" // store textContent
 *                 elementPropertyToBeBoundBySlot2: anchorElement // store element
 *             },
 *         };
 *     };`
 *
 *     ```
 * @privateRemarks
 * Either an element or the element's textContent is stored. In both cases, interpolation is supported. In order to be
 * able to run interpolation multiple times, the original textContent is stored, and never modified.
 *
 * When storing the element ({@link SlotOptions.nativeElement} is true), the element and its textContent is stored.
 * When interpolation runs, it will run against the original textContent. The interpolated string will then be the new
 * value of the element's textContent and the element is returned to the caller.
 *
 * When storing the element's textContent ({@link SlotOptions.nativeElement} is false), the same principle holds.
 * Interpolation is performed on the original textContent which is never modified. The interpolated string is returned
 * to the caller.
 * ```
 *
 * @param options {@link SlotOptions}
 *
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Slot(options?: SlotOptions): (target: unknown, propertyKey: string) => void;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Slot(nameOrOptions?: SlotOptions | string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (target: any, propertyKey: string) => {
    let entry: SlotEntry | undefined;
    const options = resolveSlotOptions(propertyKey, nameOrOptions);

    Object.defineProperty(target, propertyKey, {
      get: function () {
        return options.nativeElement ? toElementFromEntry(entry, this) : toTextContentFromEntry(entry, this);
      },
      set: function (value: HTMLElement | string) {
        entry = options.nativeElement ? tryCreateEntryFromElement(options, value) : tryCreateEntryFromTextContent(options, value);
      },
    });

    const element =
      getQueryBase().querySelector<HTMLElement>(`[${options.bindingPropertyName}]`) ??
      getQueryBase().querySelector<HTMLElement>(`[${camelToDashCase(options.bindingPropertyName)}]`);

    if (element) {
      entry = toEntryFromDomElement(element);
      return;
    }

    logError(
      `${target.constructor.name}: failed to initialize Slot for property "${propertyKey}". ` +
        `No element with attribute "${kebabToCamelCase(options.bindingPropertyName)}", ` +
        `or "${camelToDashCase(options.bindingPropertyName)}", was found in the DOM`
    );
  };
}

/**
 * Decorator that finds Vattenfall Element projected nodes by their attribute names and returns them, or their
 * textContents  in an array. The Slots decorator is used to resolve multiple projected nodes, *having the same
 * attribute name*, into component class properties as arrays. It is comparable to {@link Slots}, but works
 * with collections.
 *
 * @example
 *    Project two span elements, first in an array of HTMLSpanElement, holding the elements themselves, and
 *    secondly, in an array of strings, holding the elements' textContent.
 * ```html
 *     <custom-element>
 *         <span domUniqueCamelCaseAttribute>${textContent1}</span>
 *         <span domUniqueCamelCaseAttribute>${textContent2}</span>
 *      </custom-element>
 * ```
 * ```ts
 *        // Will contain two instances of HTMLSpanElement, as nativeElement is true
 *        @Slots({ bindingPropertyName: "domUniqueCamelCaseAttribute",  nativeElement: true })
 *        readonly spanElements?: Array<HTMLSpanElement>;
 *
 *        // Will contain two textContent strings, as nativeElement is false
 *        @Slots({ bindingPropertyName: "domUniqueCamelCaseAttribute",  nativeElement: false })
 *        readonly textContentArray?: Array<string>;
 *
 *
 * @param slotOptions {@link SlotOptions}
 *
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Slots(slotOptions?: SlotOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (target: any, propertyKey: string) => {
    let entries: Array<SlotEntry | undefined> = [];
    const options = resolveSlotOptions(propertyKey, slotOptions);

    Object.defineProperty(target, propertyKey, {
      get: function () {
        return entries.map((entry) =>
          options.nativeElement ? toElementFromEntry(entry, this) : toTextContentFromEntry(entry, this)
        );
      },
      set: function (values: Array<HTMLElement | string>) {
        entries = values.map((value) =>
          options.nativeElement ? tryCreateEntryFromElement(options, value) : tryCreateEntryFromTextContent(options, value)
        );
      },
    });

    const camelCased = getQueryBase().querySelectorAll(`[${options.bindingPropertyName}]`);
    if (camelCased.length) {
      entries = Array.from(camelCased).map((element) => toEntryFromDomElement(element as HTMLElement));
      return;
    }

    const dashCased = getQueryBase().querySelectorAll(`[${camelToDashCase(options.bindingPropertyName)}]`);
    if (dashCased.length) {
      entries = Array.from(dashCased).map((element) => toEntryFromDomElement(element as HTMLElement));
      return;
    }

    logError(
      `${target.constructor.name}: failed to initialize Slots for property "${propertyKey}". ` +
        `No element with attribute "${kebabToCamelCase(options.bindingPropertyName)}", ` +
        `or "${camelToDashCase(options.bindingPropertyName)}", was found in the DOM`
    );
  };
}

/**
 * Returns the markup to use for finding slotted elements.
 *
 * @privateRemarks
 * We use the story source code to identify slots when running in Storybook. This is because the component is created inside a another component and the projected nodes are removed before we can detect them here otherwise.
 * @returns Base HTML element
 */
// TODO (stefan): This is not 100%. Problem is that sometimes __STORYBOOK_STORY_STORE__ and sometimes it's not.
// We can get around this by either 1) put this logic inside the getter or 2) setting the element and entry last in call-stack (by wrapping it in a setTimeout).
// This will require some code refactoring though to make sure the tests work and I don't feel like doing that right now.
const getQueryBase = () => {
  if (isStorybookRuntime) {
    const templateElement = document.createElement("template");
    const source = Object.values(window.__STORYBOOK_STORY_STORE__?._stories)
      .map((story: any) => story?.parameters?.docs?.source?.code ?? "")
      .join("");
    templateElement.innerHTML = source;
    return templateElement.content;
  }
  return document.body;
};

/**
 * Creates a {@link SlotOptions} object.
 * @param propertyName Default property binding name
 * @param nameOrOptions Either {@link SlotOptions} or a custom property binding name
 */
// TODO (stefan): Consider renaming to getOptions. Also we are exporting internal implementation logic through our public API. Consider refactor test file.
export const resolveSlotOptions = (propertyName: string, nameOrOptions?: SlotOptions | string): Required<SlotOptions> => {
  const optionsObject = typeof nameOrOptions === "object" ? nameOrOptions : undefined;
  if (optionsObject) {
    return Object.freeze({
      bindingPropertyName: optionsObject.bindingPropertyName || propertyName,
      nativeElement: optionsObject.nativeElement || false,
    });
  }
  return Object.freeze({
    bindingPropertyName: (nameOrOptions as string) || propertyName,
    nativeElement: false,
  });
};

/**
 * Extracts element from {@param entry}, interpolates its textContent, and returns the element.
 * @param entry {@link SlotEntry}
 * @param target interpolation value provider, @see {@link interpolate})
 * @returns extracted HTMLElement or undefined, if {@param entry} was undefined
 */
const toElementFromEntry = (entry: SlotEntry | undefined, target: unknown): HTMLElement | undefined => {
  if (!entry?.element) return undefined;

  const { element, textContent } = entry;
  element.textContent = interpolate(target, textContent);
  return element;
};

/**
 * Extracts textContent from {@param entry}, interpolates it, and returns the textContent.
 * @param entry {@link SlotEntry}
 * @param target interpolation value provider, @see {@link interpolate})
 * @returns extracted HTMLElement or undefined, if {@param entry} was undefined
 *
 * @param entry
 * @param target
 */
const toTextContentFromEntry = (entry: SlotEntry | undefined, target: unknown): string => {
  if (!entry) return "";

  const { textContent } = entry;
  return interpolate(target, textContent);
};

/** Creates {@link SlotEntry} from element */
const toEntryFromDomElement = (element: HTMLElement): SlotEntry => {
  const textContent = normalizeWhitespace(element.textContent ?? "");
  return { element, textContent };
};

/**
 * Create and returns {@link SlotEntry} from {@link value}, if {@link value} is instance of **HTMLElement**. Otherwise, returns
 * undefined.
 * ```
 * @privateRemarks
 * The purpose of this function is to enforce integrity when explicitly setting a slot: when
 * {@link SlotOptions.nativeElement} is true, then {@link value} must be an instance of HTMLElement.
 */
// TODO (stefan): Options declared but never used. Investigate reason and remove.
const tryCreateEntryFromElement = (options: SlotOptions, value: unknown): SlotEntry | undefined => {
  if (!(value instanceof HTMLElement)) {
    logError(`Can not create entry: option "nativeElement" is true, but value ${value} is not instance of HTMLElement`);
    return undefined;
  }

  const textContent = normalizeWhitespace(value?.textContent ?? "");
  return { element: value, textContent };
};

/**
 * Create and returns {@link SlotEntry} from {@link value}, if {@link value} is a **string**. Otherwise, returns undefined.
 * ```
 * @privateRemarks
 * The purpose of this function is to enforce integrity when explicitly setting a slot: when
 * {@link SlotOptions.nativeElement} is false, then {@link value} must be a string.
 */
// TODO (stefan): Options declared but never used. Investigate reason and remove.
const tryCreateEntryFromTextContent = (options: SlotOptions, value: unknown): SlotEntry | undefined => {
  if (typeof value !== "string") {
    logError(`Can not create entry: option "nativeElement" is false, but value ${value} is not of type string`);
    return undefined;
  }
  return { textContent: normalizeWhitespace(value ?? "") };
};

/** Regex pattern that matches tabs */
const TABS_PATTERN = /\t/g;

/** Regex patterns that matches multiple (two or more) spaces */
const MULTIPLE_SPACES_PATTERN = /\s{2,}/g;

/**
 * Normalizes whitespace in {@link value} by removing tabs and replacing multiple spaces with single spaces
 * @param value
 */
// TODO (stefan): Consider renaming, i.e. getTextContent
const normalizeWhitespace = (value: string) => value.replace(TABS_PATTERN, "").replace(MULTIPLE_SPACES_PATTERN, " ").trim();

/** Regex pattern that matches tokens ("{ token }") enclosed in curly braces and captures the value ("token") within the curly braces. */
const INTERPOLATION_PATTERN = /{(.*?)}/g;

/**
 * Interpolates dynamic values into {@link value}, a string that may or may not contain interpolation placeholders.
 * Interpolation placeholders are on the form "{ valueToBeReplaced }" where "valueToBeReplaced" is a property name that
 * {@link target} is expected to define. If the string does not contain any placeholders the original string is returned
 * unmodified. If no property accessor can be found, an error is printed. Interpolation runs using "best effort",
 * replacing what can be replaced; skipping what can't.
 *
 * Interpolation depends on a (target) instance, therefore statics are not supported.
 *
 * @param target Class that is expected to define a property accessor for each interpolation placeholder.
 * @param value The string on which interpolation should be performed
 */
// TODO (stefan): Consider renaming, i.e. getInterpolatedTextContent
const interpolate = (target: any, value: string) => {
  let interpolated = value;
  Array.from(value.matchAll(INTERPOLATION_PATTERN)).forEach(([placeholder, capture]) => {
    const propertyName = capture.trim();
    if (propertyName in target) interpolated = interpolated.replace(placeholder, target[propertyName]);
    else
      logError(
        `Failed to interpolate value for placeholder "${placeholder}" into "${value}": ` +
          `required property "get ${propertyName}()" not found on class "${target.constructor.name}".`
      );
  });
  return interpolated;
};
