import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      withCredentials: true,
      /**
       * We need to clear the cache, and set expires to a past date because of a bug in IE11.
       * IE11 caches the last call to the Self Service API and returns the same results as the previous login
       * So in case you login with two different customers, the second login will show the same contract as the first login.
       * By setting no-cache, we make lazy IE11 call every time and do its job.
       */
      headers: request.headers
        .set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache")
        .set("Expires", "Thu, 01 Jan 1970 00:00:00 GMT")
        .set("If-Modified-Since", "0"),
    });
    return next.handle(newRequest);
  }
}
export const cookieInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CookieInterceptor,
  multi: true,
};
