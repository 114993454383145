/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<unknown>> = new Map();
  private cacheUrlList: Array<string> = ["my-consumption"];
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /* Cache backend response for unique url in key-value pair 
      example: { key: https://domain/somePath, 
                value: HttpResponse<any>({ key1: value1, key2: value2 }) }
      */
    if (request.method === "GET" && this.isUrlInCacheList(request.url)) {
      const cachedResponse = this.cache.get(request.urlWithParams);
      return cachedResponse ? of(cachedResponse) : this.handleRequest(request, next);
    }
    /* If new set of data has been update/deleted for same backend api url, then delete 
      the key(url) from cached map object. So next GET request could fetch updated data */
    if (["POST", "PUT", "DELETE", "PATCH"].includes(request.method) && this.cache.has(request.urlWithParams))
      this.cache.delete(request.urlWithParams);
    return next.handle(request);
  }

  handleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((stateEvent) => {
        if (stateEvent instanceof HttpResponse) {
          this.cache.set(request.urlWithParams, stateEvent.clone());
        }
      })
    );
  }

  isUrlInCacheList(requestUrl: string) {
    return this.cacheUrlList.filter((cachedUrl) => requestUrl.includes(cachedUrl)).length > 0;
  }
}

export const httpCacheInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpCacheInterceptor,
  multi: true,
};
