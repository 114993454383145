import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";

import { logError } from "../functions";

/**
 * Establishes a WebSocket connection as an RxJS observable.
 *
 * **Example**
 *
 * ```typescript
 * ...
 * constructor(private webSocketService: WebSocketService<any>) {
 *   // Make sure to connect before calling send or close
 *   webSocketService.connect("ws://...");
 * }
 * ```
 */
@Injectable({
  providedIn: "root",
})
export class WebSocketService<T> {
  /** Stream of messages received through WebSocket. */
  get messages(): Observable<T> | undefined {
    return this.$webSocket?.asObservable();
  }

  private $webSocket?: WebSocketSubject<T>;

  /** Connects to WebSocket. */
  connect(url: string) {
    this.$webSocket = webSocket(url);
  }

  /** Closes the connection. */
  close() {
    if (!this.$webSocket) logError("No websocket connection. The 'close' method was called before calling 'connect'.");
    else this.$webSocket?.complete();
  }

  /** Enqueues data to be transmitted. */
  send(payload: T) {
    if (!this.$webSocket) logError("No websocket connection. The 'send' method was called before calling 'connect'.");
    else this.$webSocket?.next(payload);
  }
}
