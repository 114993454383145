import { dateFromInput, InputDate, InputType, isInputType } from "./date";

/**
 * Type describing the allowed values for a date input
 */
export type DateInput = Date | InputDate | null | undefined;

function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

/**
 * Coerces a data-bound value (typically a date, week, month or year formatted as a string) to a date object.
 */
export function coerceDateProperty(value: Date | InputType): Date;
export function coerceDateProperty(value: unknown): null;
export function coerceDateProperty(value: unknown): Date | null {
  if (isDate(value)) return value;
  if (isInputType(value)) return dateFromInput(value);
  return null;
}
