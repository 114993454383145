/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { Story } from "@storybook/angular/types-6-0";

/**
 * The type description a Storybook story options
 */
export type StoryOptions = {
  /**
   * Short description about story
   */
  description?: string;
  /**
   * The name of the story if different than the exported variable declaration
   */
  name?: string;
  /**
   * CSS styles for the parent host element
   */
  style?: string;
  /**
   * Override default properties
   */
  props?: Record<string, unknown>;
};

/**
 * Returns a Storybook story using the provided template with the template as its source code
 * @param template HTML template markup of the story
 * @param options Short description about story
 * @returns Storybook story
 */
export function getStory<T>(template: string, options?: StoryOptions) {
  const storyFunction: Story<T> = () => ({
    template,
    props: options?.props,
    styles: [options?.style ?? ""],
  });
  storyFunction.parameters = {
    docs: {
      source: {
        code: template,
        iframeHeight: 400,
      },
      storyDescription: options?.description,
    },
  };
  if (options?.name) storyFunction.storyName = options.name;
  return storyFunction;
}

declare global {
  interface Window {
    __STORYBOOK_ADDONS: any;
    __STORYBOOK_STORY_STORE__: any;
  }
}

/**
 * Whether the application is running in Storybook
 */
export const isStorybookRuntime = "__STORYBOOK_ADDONS" in window;
