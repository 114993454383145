/**
 * Calculates the z-score normalization of an array of values
 * @param - The array of values to normalize
 * @returns An array of z-score normalized values where non-number entries are replaced with NaN
 */
export function getZScores(values: Array<number | null | undefined>): Array<number> {
  // Remove non-numbers from value list
  const numberValues = values.filter((value) => typeof value === "number") as Array<number>;
  // Calculate the mean of the values array
  const meanValue = numberValues.reduce((sum, value) => sum + value, 0) / numberValues.length;
  // Calculate the standard deviation of the values array
  const standardDeviation = Math.sqrt(
    numberValues.reduce((varianceValue, value) => varianceValue + (value - meanValue) ** 2, 0) / numberValues.length
  );
  // Use the map method to iterate over the values array and calculate the z-score for each value
  return values.map((value) => (value == null || isNaN(value) ? NaN : (value - meanValue) / standardDeviation));
}
