import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalStoreService<T> {
  get store() {
    return this.$store.asObservable();
  }

  get snapshot() {
    return Object.freeze(this.getGlobalStore()) as T;
  }

  private $store: ReplaySubject<T> = new ReplaySubject(0);
  private _globalStoreEvent = new Event("globalStore");

  constructor() {
    this.$store.next(this.getGlobalStore());
    document.addEventListener("globalStore", () => {
      this.$store.next(this.getGlobalStore());
    });
  }

  dispatch(key: keyof T, value: unknown) {
    this.$store.next(this.getGlobalStore());
    this.setGlobalStore(key, value);
    document.dispatchEvent(this._globalStoreEvent);
  }

  private getGlobalStore() {
    return JSON.parse(sessionStorage.getItem("VattenfallElementsGlobalStore") ?? "{}");
  }

  private setGlobalStore(key: keyof T, value: unknown) {
    sessionStorage.setItem("VattenfallElementsGlobalStore", JSON.stringify({ ...this.getGlobalStore(), [key]: value }));
  }
}
