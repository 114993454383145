import { differenceInYears, getDaysInMonth, getYear, isWeekend } from "date-fns";
import {
  __,
  addIndex,
  both,
  equals,
  gt,
  gte,
  includes,
  isEmpty,
  last,
  lte,
  map,
  modulo,
  multiply,
  pipe,
  replace,
  split,
  splitAt,
  splitEvery,
  subtract,
  sum,
  take,
  takeLast,
  toLower,
  when,
  zipWith,
} from "ramda";

import { isFinnishPublicHoliday, isSwedishPublicHoliday } from "./date";

/**
 * Regular expression that matches valid swedish emails.
 *
 * this regex matches swedish emails as implemented by the Orderhandler API, where
 * - `john.doe` is a unlimited number of lowercase letters and numericals, with a underscore, dash or dot as accepted special characters
 * - `@` seperator for between the first and second sections
 * - `corp.example` is the host and subdomain represented as a unlimited number of lowercase letters and numericals, with a dash as accepted special character
 * - `.com` the domain extention with a minimum of 2 and maximum of 4 lowercase letters
 */
const SWEDISH_EMAIL = /^[a-z0-9_-]+(\.[a-z0-9_-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

/**
 * Regular expression that matches valid finnish emails.
 *
 * this regex matches finnish emails as implemented by backend services, where
 * - `jöhn.doe` is a unlimited number of lowercase letters (including å, ä and ö) and numericals, with a underscore, dash or dot as accepted special characters
 * - `@` seperator for between the first and second sections
 * - `corp.example` is the host and subdomain represented as a unlimited number of lowercase letters (including å, ä and ö) and numericals, with a dash as accepted special character
 * - `.com` the domain extention with a unlimited letters (including å, ä and ö) and numericals
 */
const FINNISH_EMAIL =
  /^[a-ö][a-ö|0-9|]*([a-ö|0-9|._-]+)*([.][a-ö|0-9]+([_][a-ö|0-9]+)*)*@[a-ö][a-ö|0-9|]*.([a-ö][a-ö|0-9]*(.[a-ö][a-ö|0-9]*)?)$/;
/**
 * Regular expression that matches valid finnish business id.
 *
 * this regex matches finnish business id of format `2108804-8`, where
 * - `2108804` is a 7 digit business number generated by national board of taxation following 11 mod 2 algorithm.
 * - `-` seperator for number and checksum digit
 * - `8` is checksum digit
 */
const FINNISH_ORGANIZATION_NUMBER_REGEXP = /^(?!0000000-0).*(\d{7}-\d{1}?)$/;

/**
 * Regular expression that matches valid swedish organisation number.
 *
 * this regex matches swedish organisation of format `556036-2138`, where
 * - `556036213` first 9 digit except dash (`-`) follows luhn algorithm to generated checsum digit 8.
 * - `-` seperator for between 6 & 4 digit
 * - `8` is checksum digit
 */
const SWEDISH_ORGANIZATION_NUMBER_REGEXP = /^(?!000000-0000)(\d{6}-\d{4}?)$/;

/**
 * Regular expression that matches valid Finnish personal numbers.
 *
 * This regex matches Finnish personal numbers of formats like `051163-050Y`, `211200A598H`, or `010594Y9032`, where:
 * - `051163` or `211200` or `010594`: The first 6 digits represent a valid date in the format `ddMMyy`.
 * - `-`, `+`, `A`, `B`, `C`, `D`, `E`, `F`, `Y`, `X`, `W`, `V`, `U`: These represent the century of birth and are called intermediate characters.
 *   - For those born in or after 2000, it can be `A`, `B`, `C`, `D`, `E`, or `F`.
 *   - For those born in the 20th century, it can be `-`, `Y`, `X`, `W`, `V`, or `U`.
 *   - For those born in the 19th century, it remains `+`.
 * - `050` or `598` or `903`: The next 3 digits are an identification number, which is odd for males and even for females.
 * - `Y`, `H`, `2`: The last character is a control character.
 */
const FINNISH_PERSONAL_NUMBER_REGEXP =
  /^([0][1-9]|[1|2][0-9]|[3][0|1])([0][1-9]|[1][0-2])([0-9]{2})([-+A-FYXWVU])([0-9]{3})([A-Z0-9])$/;

/**
 * Regular expression that matches valid swedish personal number.
 *
 * this regex matches swedish personal of format `19640823-3234`, where
 * - `19640823` or `640823` first 8 or 6 digit represent valid date of format `yyyyMMdd`
 * - `-` seperator between 8/6 and 4 digit
 * - `640823323` this combined digit except dash (`-`) follows luhn algorithm to generated checsum digit 4
 * - `4` is checksum character
 */
const SWEDISH_PERSONAL_NUMBER_REGEXP = /^(19|20)?([0-9]{2})([0][1-9]|[1][0-2])([0-9]{2})([-+|\s]?\d{4})$/;

/**
 * Regular expression that matches valid finnish phone number.
 *
 * this regex matches finnish phone numer of some format like `+358406361091`, where
 * - `+358` represent country code
 * - `406361091` represent remain part of phone number
 */
const FINNISH_PHONE_NUMBER_REGEXP = /^(((\+|0{2})(([1-9]{3})|[1-9]{2}[ ][1-9]))|(0)[1-9]{1})([0-9 -]{6,10})$/;

/**
 * Regular expression that matches valid swedish phone number.
 *
 * this regex matches finnish phone numer of some format like `+4620-820000`, where
 * - `+46` represent country code
 * - `20-820000` represent remain part of phone number
 */
const SWEDISH_PHONE_NUMBER_REGEXP = /^(00[1-9][1-9]|\+|0)[1-9][0-9]{0,2}[0-9\-/]{5,8}$/;

/**
 * Regular expression that matches valid swedish pod id.
 *
 * this regex matches swedish phone podid of format like `735999100054384456`, where
 * - `735999100054384456` represent unique podid for apartment/house which follows GS-1 standard algorithm to validate
 */
const SWEDISH_PREMISE_ID_REGEXP = /^[0-9]{18}$/;

/**
 * Regular expression that matches valid finnish postal code.
 *
 * this regex matches finnish postal code of format like `62100`, where
 * - `62100` represent finnish valid postal code
 */
const FINNISH_POSTAL_CODE_REGEXP = /^\d{5}$/;

/**
 * Regular expression that matches valid swedish postal code.
 *
 * this regex matches swedish postal code of format like `169 56`, where
 * - `169 56` or `16956` represent swedish valid postal code
 */
const SWEDISH_POSTAL_CODE_REGEXP = /^[1-9]{1}[0-9]{2}\s?\d{2}$/;

/**
 * Regular expression that matches valid international postal code.
 *
 * this regex matches swedish postal code of format like `FE16956`, where
 * - `169 56` or `16956` or `FE16956` represent international valid postal code
 */
const INTERNATIONAL_POSTAL_CODE_REGEXP = /^(?=.*\d)[a-zA-Z1-9][a-zA-Z0-9 -]{1,9}$/;

/**
 * Regular expression that matches valid password according to Business Rule 16.
 * This expression is modified to handle whitespace and omits the final "." to match any character.
 *
 * The password must contain
 * - between 8 and 16 characters
 * - one uppercase letter,
 * - one lowercase letter,
 * - one digit and
 * - one special character.
 * - example 'Abcdefghi1$'
 * - no spaces
 */
const PASSWORD_BR_16 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

/**
 * Regular expression that matches valid characters for street names.
 *
 * The street name must contain
 * - a limit of 100 characters
 * - between a-z and A-Z characters
 * - can also include special characters like Å, Ä, Ö, Æ, Ø, Ü, ß as added below,
 */
const STREET_NAME_INVOICE_REGEXP = /^([a-zA-Z0-9åäöÅÄÖÆæØøÜüß \-.]){1,100}$/;

/**
 * Regular expression that matches valid characters for care of address.
 *
 * The care of address must contain
 * - a limit of 150 characters
 * - between a-z and A-Z characters
 * - can also include special characters like Å, Ä, Ö, Æ, Ø, Ü, ß as added below,
 */
const CARE_OF_ADDRESS_REGEX = /^([a-zA-Z0-9åäöÅÄÖÆæØøÜüß \-()]){0,150}$/;

/**
 * Regular expression that matches valid characters Swedish, Finnish, and international postal towns.
 *
 * The postal town must contain
 * - a limit of 60 characters
 * - between a-z and A-Z characters
 * - can also include special characters like Å, Ä, Ö, Æ, Ø, Ü, ß as added below,
 */
const SWEDISH_FINNISH_POSTAL_TOWN_REGEXP = /^([a-zA-ZåäöÅÄÖÆæØøÜüß \-_]){1,60}$/;

/**
 * Validates a domain extention against a list of official domain registries (filtered by length 2 to 4 characters).
 * @see [IANA Domain Registry](http://data.iana.org/TLD/tlds-alpha-by-domain.txt)
 * @param emailAddress The email address to validate
 */
const hasValidDomainExtension = (emailAddress: string) => {
  // list of official domain extentions from the IANA domain registry
  // filtered by 2 to 4 characters
  // @ts-ignore Keep as list
  const IANA_DOMAIN_REGISTRY = <const>[
    "aaa",
    "aarp",
    "abb",
    "abc",
    "able",
    "ac",
    "aco",
    "ad",
    "adac",
    "ads",
    "ae",
    "aeg",
    "aero",
    "af",
    "afl",
    "ag",
    "ai",
    "aig",
    "akdn",
    "al",
    "ally",
    "am",
    "amex",
    "anz",
    "ao",
    "aol",
    "app",
    "aq",
    "ar",
    "arab",
    "army",
    "arpa",
    "art",
    "arte",
    "as",
    "asda",
    "asia",
    "at",
    "au",
    "audi",
    "auto",
    "aw",
    "aws",
    "ax",
    "axa",
    "az",
    "ba",
    "baby",
    "band",
    "bank",
    "bar",
    "bb",
    "bbc",
    "bbt",
    "bbva",
    "bcg",
    "bcn",
    "bd",
    "be",
    "beer",
    "best",
    "bet",
    "bf",
    "bg",
    "bh",
    "bi",
    "bid",
    "bike",
    "bing",
    "bio",
    "biz",
    "bj",
    "blog",
    "blue",
    "bm",
    "bms",
    "bmw",
    "bn",
    "bo",
    "bofa",
    "bom",
    "bond",
    "boo",
    "book",
    "bot",
    "box",
    "br",
    "bs",
    "bt",
    "buy",
    "buzz",
    "bv",
    "bw",
    "by",
    "bz",
    "bzh",
    "ca",
    "cab",
    "cafe",
    "cal",
    "call",
    "cam",
    "camp",
    "car",
    "care",
    "cars",
    "casa",
    "case",
    "cash",
    "cat",
    "cba",
    "cbn",
    "cbre",
    "cbs",
    "cc",
    "cd",
    "ceo",
    "cern",
    "cf",
    "cfa",
    "cfd",
    "cg",
    "ch",
    "chat",
    "ci",
    "citi",
    "city",
    "ck",
    "cl",
    "club",
    "cm",
    "cn",
    "co",
    "com",
    "cool",
    "coop",
    "cpa",
    "cr",
    "crs",
    "csc",
    "cu",
    "cv",
    "cw",
    "cx",
    "cy",
    "cyou",
    "cz",
    "dad",
    "data",
    "date",
    "day",
    "dclk",
    "dds",
    "de",
    "deal",
    "dell",
    "desi",
    "dev",
    "dhl",
    "diet",
    "dish",
    "diy",
    "dj",
    "dk",
    "dm",
    "dnp",
    "do",
    "docs",
    "dog",
    "dot",
    "dtv",
    "duck",
    "dvag",
    "dvr",
    "dz",
    "eat",
    "ec",
    "eco",
    "edu",
    "ee",
    "eg",
    "er",
    "erni",
    "es",
    "esq",
    "et",
    "eu",
    "eus",
    "fage",
    "fail",
    "fan",
    "fans",
    "farm",
    "fast",
    "fi",
    "fiat",
    "fido",
    "film",
    "fire",
    "fish",
    "fit",
    "fj",
    "fk",
    "flir",
    "fly",
    "fm",
    "fo",
    "foo",
    "food",
    "ford",
    "fox",
    "fr",
    "free",
    "frl",
    "ftr",
    "fun",
    "fund",
    "fyi",
    "ga",
    "gal",
    "game",
    "gap",
    "gay",
    "gb",
    "gbiz",
    "gd",
    "gdn",
    "ge",
    "gea",
    "gent",
    "gf",
    "gg",
    "ggee",
    "gh",
    "gi",
    "gift",
    "gl",
    "gle",
    "gm",
    "gmbh",
    "gmo",
    "gmx",
    "gn",
    "gold",
    "golf",
    "goo",
    "goog",
    "gop",
    "got",
    "gov",
    "gp",
    "gq",
    "gr",
    "gs",
    "gt",
    "gu",
    "guge",
    "guru",
    "gw",
    "gy",
    "hair",
    "haus",
    "hbo",
    "hdfc",
    "help",
    "here",
    "hgtv",
    "hiv",
    "hk",
    "hkt",
    "hm",
    "hn",
    "host",
    "hot",
    "how",
    "hr",
    "hsbc",
    "ht",
    "hu",
    "ibm",
    "icbc",
    "ice",
    "icu",
    "id",
    "ie",
    "ieee",
    "ifm",
    "il",
    "im",
    "imdb",
    "immo",
    "in",
    "inc",
    "info",
    "ing",
    "ink",
    "int",
    "io",
    "iq",
    "ir",
    "is",
    "ist",
    "it",
    "itau",
    "itv",
    "java",
    "jcb",
    "je",
    "jeep",
    "jio",
    "jll",
    "jm",
    "jmp",
    "jnj",
    "jo",
    "jobs",
    "jot",
    "joy",
    "jp",
    "jprs",
    "kddi",
    "ke",
    "kfh",
    "kg",
    "kh",
    "ki",
    "kia",
    "kim",
    "kiwi",
    "km",
    "kn",
    "kp",
    "kpmg",
    "kpn",
    "kr",
    "krd",
    "kred",
    "kw",
    "ky",
    "kz",
    "la",
    "land",
    "lat",
    "law",
    "lb",
    "lc",
    "lds",
    "lego",
    "lgbt",
    "li",
    "lidl",
    "life",
    "like",
    "limo",
    "link",
    "live",
    "lk",
    "llc",
    "llp",
    "loan",
    "loft",
    "lol",
    "love",
    "lpl",
    "lr",
    "ls",
    "lt",
    "ltd",
    "ltda",
    "lu",
    "luxe",
    "lv",
    "ly",
    "ma",
    "maif",
    "man",
    "map",
    "mba",
    "mc",
    "md",
    "me",
    "med",
    "meet",
    "meme",
    "men",
    "menu",
    "mg",
    "mh",
    "mil",
    "mini",
    "mint",
    "mit",
    "mk",
    "ml",
    "mlb",
    "mls",
    "mm",
    "mma",
    "mn",
    "mo",
    "mobi",
    "moda",
    "moe",
    "moi",
    "mom",
    "moto",
    "mov",
    "mp",
    "mq",
    "mr",
    "ms",
    "msd",
    "mt",
    "mtn",
    "mtr",
    "mu",
    "mv",
    "mw",
    "mx",
    "my",
    "mz",
    "na",
    "nab",
    "name",
    "navy",
    "nba",
    "nc",
    "ne",
    "nec",
    "net",
    "new",
    "news",
    "next",
    "nf",
    "nfl",
    "ng",
    "ngo",
    "nhk",
    "ni",
    "nico",
    "nike",
    "nl",
    "no",
    "now",
    "np",
    "nr",
    "nra",
    "nrw",
    "ntt",
    "nu",
    "nyc",
    "nz",
    "obi",
    "off",
    "ollo",
    "om",
    "one",
    "ong",
    "onl",
    "ooo",
    "open",
    "org",
    "ott",
    "ovh",
    "pa",
    "page",
    "pars",
    "pay",
    "pccw",
    "pe",
    "pet",
    "pf",
    "pg",
    "ph",
    "phd",
    "pics",
    "pid",
    "pin",
    "ping",
    "pink",
    "pk",
    "pl",
    "play",
    "plus",
    "pm",
    "pn",
    "pnc",
    "pohl",
    "porn",
    "post",
    "pr",
    "pro",
    "prod",
    "prof",
    "pru",
    "ps",
    "pt",
    "pub",
    "pw",
    "pwc",
    "py",
    "qa",
    "qpon",
    "qvc",
    "raid",
    "re",
    "read",
    "red",
    "reit",
    "ren",
    "rent",
    "rest",
    "rich",
    "ril",
    "rio",
    "rip",
    "rmit",
    "ro",
    "room",
    "rs",
    "rsvp",
    "ru",
    "ruhr",
    "run",
    "rw",
    "rwe",
    "sa",
    "safe",
    "sale",
    "sap",
    "sarl",
    "sas",
    "save",
    "saxo",
    "sb",
    "sbi",
    "sbs",
    "sc",
    "sca",
    "scb",
    "scot",
    "sd",
    "se",
    "seat",
    "seek",
    "ses",
    "sew",
    "sex",
    "sexy",
    "sfr",
    "sg",
    "sh",
    "shaw",
    "shia",
    "shop",
    "show",
    "si",
    "silk",
    "sina",
    "site",
    "sj",
    "sk",
    "ski",
    "skin",
    "sky",
    "sl",
    "sm",
    "sn",
    "sncf",
    "so",
    "sohu",
    "song",
    "sony",
    "soy",
    "spa",
    "spot",
    "sr",
    "srl",
    "ss",
    "st",
    "star",
    "stc",
    "su",
    "surf",
    "sv",
    "sx",
    "sy",
    "sz",
    "tab",
    "talk",
    "tax",
    "taxi",
    "tc",
    "tci",
    "td",
    "tdk",
    "team",
    "tech",
    "tel",
    "teva",
    "tf",
    "tg",
    "th",
    "thd",
    "tiaa",
    "tips",
    "tj",
    "tjx",
    "tk",
    "tl",
    "tm",
    "tn",
    "to",
    "top",
    "town",
    "toys",
    "tr",
    "trv",
    "tt",
    "tube",
    "tui",
    "tv",
    "tvs",
    "tw",
    "tz",
    "ua",
    "ubs",
    "ug",
    "uk",
    "uno",
    "uol",
    "ups",
    "us",
    "uy",
    "uz",
    "va",
    "vana",
    "vc",
    "ve",
    "vet",
    "vg",
    "vi",
    "vig",
    "vin",
    "vip",
    "visa",
    "viva",
    "vivo",
    "vn",
    "vote",
    "voto",
    "vu",
    "wang",
    "wed",
    "weir",
    "wf",
    "wien",
    "wiki",
    "win",
    "wine",
    "wme",
    "work",
    "wow",
    "ws",
    "wtc",
    "wtf",
    "xbox",
    "xin",
    "xxx",
    "xyz",
    "ye",
    "yoga",
    "you",
    "yt",
    "yun",
    "za",
    "zara",
    "zero",
    "zip",
    "zm",
    "zone",
    "zw",
  ];

  return pipe(
    toLower, // parse as lowercase
    split("."), // split by dots
    last, // take the top domain from the end of the list
    includes(__, IANA_DOMAIN_REGISTRY) // check if its in the list of official domain extentions list
  )(emailAddress);
};

/**
 * Validates a Swedish Personal Number or Business Registration Number control character (according to the Luhn algorithm).
 * @see [Luhn algorithm](https://en.m.wikipedia.org/wiki/Luhn_algorithm)
 * @see [Swedish Personal Number](https://en.wikipedia.org/wiki/Personal_identity_number_(Sweden))
 * @param identificationNumber the identification number to validate
 */
const hasValidSwedishChecksum = (identificationNumber: string) => {
  const doubleEvenNumbers = addIndex<number, number>(map)((digit, index) => (index % 2 === 0 ? digit * 2 : digit));
  const sumDoubleDigitNumbers = map<number, number>((digit) => (digit > 9 ? digit - 9 : digit));
  const isDivisibleBy10 = (digit: number) => digit % 10 === 0;

  return pipe(
    //@ts-ignore Ignoring due to poor ramda typings
    replace(/\D/g, ""), // Remove non numericals
    takeLast(10), // check when value is positive number, take last 10 characters
    split(""), // Split into array
    map(Number), // Parse characters as numericals
    doubleEvenNumbers, // Double every second digit
    sumDoubleDigitNumbers, // Add double digits together to get single digit numbers only
    sum, // Sum of all digits
    isDivisibleBy10 // Checks that the sum is divisible by 10
  )(identificationNumber);
};

/**
 * Returns birthdate from a Swedish or Finnish personal number as a Date object
 */
const generateDateFromDatePart = (datePart: number[], region: string, controlCharacter: string) => {
  switch (region) {
    case "se": {
      const [year, month, day] = datePart;
      //assuming day is valid day
      let validDay = day;
      //check control character '+', if person is greater than 100 year, else fecth current year
      const baseYear = controlCharacter === "+" ? getYear(new Date()) - 100 : getYear(new Date());
      //calculation to get person's born year
      const fullYear = baseYear - ((baseYear - year) % 100);
      //check if days are valid in personal number
      let isValidDays = day > 0 && day <= getDaysInMonth(new Date(fullYear, month - 1));
      //check if its co-ordination number, if days are not valid in previous step
      if (!isValidDays) {
        isValidDays = day > 60 && day <= getDaysInMonth(new Date(fullYear, month - 1)) + 60;
        //if its coordination number subtract 60 in it.
        validDay = isValidDays ? day - 60 : day;
      }
      //return date
      return new Date(fullYear, month - 1, validDay);
    }
    // Create Date object from Finnish personal number
    case "fi": {
      const [day, month, year] = datePart;
      //control character to indicate century mapping eg. "A" equals 2000, "+" equals 1800, "-" equals 1900
      const baseYear = controlCharacter === "A" ? 2000 : controlCharacter === "+" ? 1800 : 1900;
      //add to form person's born year
      const fullYear = baseYear + year;
      //return date
      return new Date(fullYear, month - 1, day);
    }
    default:
      throw Error(
        `[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'minAge' and 'maxAge'.`
      );
  }
};

/**
 * Validates the personal number has valid date and age is within min and max range.
 * @param personalNumber the personal number to validate
 * @param region indicator to calculate age in yyMMdd or ddMMyy format
 * @param minAge the person's minimum age limit
 * @param maxAge the person's maximum age limit
 */
const hasValidAgeRange = (personalNumber: string, region: string, minAge = 0, maxAge = 999) => {
  const getBirthDate = (datePart: number[]) => generateDateFromDatePart(datePart, region, personalNumber.charAt(6));
  const getAge = (birthDate: Date) => differenceInYears(new Date(), birthDate);
  const isValidAge = both(lte(__, maxAge), gte(__, minAge));

  return pipe(
    //@ts-ignore Ignoring due to poor ramda typings
    replace(/\D/g, ""), // Remove non numericals
    //@ts-ignore Ignoring due to poor ramda typings
    takeLast(10), // Take last 10 characters
    take(6), // Take first 6 characters
    splitEvery(2), // Split it in array of 2 characters
    map(Number), // Parse characters as numericals
    getBirthDate, // get birth date from personal number
    getAge, // calculate age differences
    isValidAge // Check if age is within limit
    //@ts-ignore Ignoring due to poor ramda typings
  )(personalNumber);
};

/**
 * Validates the control character of a Finnish Personal Identity Code.
 * @see [Finnish Personal Identity Code](https://en.wikipedia.org/wiki/National_identification_number#Finland)
 * @param identificationNumber the identification number to validate
 */
const hasValidFinnishPersonalChecksum = (identificationNumber: string) => {
  const [calculationBase, controlCharacter] = splitAt(-1, identificationNumber);
  const hasMatchingControlCharacter = (checkSum: number) =>
    equals("0123456789ABCDEFHJKLMNPRSTUVWXY".charAt(checkSum), controlCharacter);

  return pipe(
    replace(/[-+A-FYXWVU]/g, ""), // Remove intermediate characters and other non-numericals
    Number, // Parse as number
    modulo(__, 31), // Divides the calculation base with 31 to get the checksum
    hasMatchingControlCharacter // Checks that control character from check sum match control character from identification number
  )(calculationBase);
};

/**
 * Validates the control character of a Finnish personal number.
 * @see [Finnish Business Identity Code](http://tarkistusmerkit.teppovuori.fi/tarkmerk.htm#y-tunnus2)
 * @param identificationNumber the identification number to validate
 */
const hasValidFinnishBusinessChecksum = (identificationNumber: string) => {
  const [calculationBase, controlCharacter] = splitAt(-1, identificationNumber);

  return pipe(
    replace(/\D/gi, ""), // Remove non numericals
    split(""), // Split into array
    map(Number), // Parse characters as numericals
    zipWith<number, number, number>(multiply, [7, 9, 10, 5, 8, 4, 2]), // Multiply each digit with a set of coefficients defined by Finnish tax authorities
    sum, // Sum of all digits to get the check sum
    modulo(__, 11), // Divide check sum by 11
    when(gt(__, 1), subtract(11)), // If the checksum is greater than 1 subtract 11
    String, // Parse it back to a string to match the type of the control character
    equals(controlCharacter) // Checks that control character from check sum match control character from identification number
  )(calculationBase);
};

/**
 * Validates the control character of a Swedish premise number.
 * @param identificationNumber the identification number to validate
 */
const hasValidSwedishPremiseChecksum = (identificationNumber: string) => {
  const tripleEvenNumbers = addIndex<number, number>(map)((digit, index) => (index % 2 ? digit : digit * 3));

  return pipe(
    split(""), // Split into array
    map(Number), // Parse characters as numericals
    tripleEvenNumbers, // Triple ever even number
    sum, // Sum all numbers
    subtract(1000), // Subtract sum from 1000
    modulo(__, 10), // Divide by 10
    equals(0) // Check that the checksum equals 0
  )(identificationNumber);
};

/**
 * Checks that the provided Swedish email address is correctly formatted and has an official domain extension.
 * @param swedishEmailAddress the email address to validate
 */
export const isValidSwedishEmailAddress = (swedishEmailAddress: string) =>
  !isEmpty(swedishEmailAddress) &&
  SWEDISH_EMAIL.test(swedishEmailAddress.toLowerCase()) &&
  hasValidDomainExtension(swedishEmailAddress);

/**
 * Checks that the provided Finnish email address is correctly formatted and has an official domain extension.
 * @param finnishEmailAddress the email address to validate
 */
export const isValidFinnishEmailAddress = (finnishEmailAddress: string) =>
  !isEmpty(finnishEmailAddress) &&
  FINNISH_EMAIL.test(finnishEmailAddress.toLowerCase()) &&
  hasValidDomainExtension(finnishEmailAddress);

/**
 * Checks that the provided Swedish personal number is correctly formatted and match the calculated checksum figure.
 * @param swedishPersonalNumber the personal number to validate
 */
export const isValidSwedishPersonalNumber = (swedishPersonalNumber: string) =>
  !isEmpty(swedishPersonalNumber) &&
  SWEDISH_PERSONAL_NUMBER_REGEXP.test(swedishPersonalNumber) &&
  hasValidSwedishChecksum(swedishPersonalNumber);

/**
 * Checks that the provided Swedish business number is correctly formatted and match the calculated checksum figure.
 * @param swedishBusinessNumber the business number to validate
 */
export const isValidSwedishBusinessNumber = (swedishBusinessNumber: string) =>
  !isEmpty(swedishBusinessNumber) &&
  SWEDISH_ORGANIZATION_NUMBER_REGEXP.test(swedishBusinessNumber) &&
  hasValidSwedishChecksum(swedishBusinessNumber);

/**
 * Checks that the provided Finnish personal number is correctly formatted and match the calculated checksum figure.
 * @param finnishPersonalNumber the personal number to validate
 */
export const isValidFinnishPersonalNumber = (finnishPersonalNumber: string) =>
  !isEmpty(finnishPersonalNumber) &&
  FINNISH_PERSONAL_NUMBER_REGEXP.test(finnishPersonalNumber) &&
  hasValidFinnishPersonalChecksum(finnishPersonalNumber);

/**
 * Checks that the provided Swedish or Finnish personal number has valid legal age.
 * @param personalNumber the personal number to validate
 * @param region region to validate against different rules
 * @param minAge minimum legal age for personal number to validate against
 * @param maxAge maximum legal age for personal number to validate against
 */
export const isValidAgeRange = (personalNumber: string, region: string, minAge?: number, maxAge?: number) =>
  !isEmpty(personalNumber) && hasValidAgeRange(personalNumber, region, minAge, maxAge);

/**
 * Checks that the provided Swedish Business Number is correctly formatted and match the calculated checksum figure.
 * @param finnishBusinessNumber the business number to validate
 */
export const isValidFinnishBusinessNumber = (finnishBusinessNumber: string) =>
  !isEmpty(finnishBusinessNumber) &&
  FINNISH_ORGANIZATION_NUMBER_REGEXP.test(finnishBusinessNumber) &&
  hasValidFinnishBusinessChecksum(finnishBusinessNumber);

/**
 * Checks that the provided Swedish phone number is correctly formatted.
 * @param swedishPhoneNumber the phone number to validate
 */
export const isValidSwedishPhoneNumber = (swedishPhoneNumber: string) =>
  !isEmpty(swedishPhoneNumber) && SWEDISH_PHONE_NUMBER_REGEXP.test(swedishPhoneNumber);

/**
 * Checks that the provided Finnish phone number is correctly formatted.
 * @param finnishPhoneNumber the phone number to validate
 */
export const isValidFinnishPhoneNumber = (finnishPhoneNumber: string) =>
  !isEmpty(finnishPhoneNumber) && FINNISH_PHONE_NUMBER_REGEXP.test(finnishPhoneNumber);

/**
 * Checks that the provided Swedish postal code is correctly formatted.
 * @param swedishPostalCode the postal code to validate
 */
export const isValidSwedishPostalCode = (swedishPostalCode: string) =>
  !isEmpty(swedishPostalCode) && SWEDISH_POSTAL_CODE_REGEXP.test(swedishPostalCode);
/**
 * Checks that the provided International postal code is correctly formated.
 * @param internationalPostalCode the postal code to validate
 */
export const isValidInternationalPostalCode = (internationalPostalCode: string) =>
  !isEmpty(internationalPostalCode) && INTERNATIONAL_POSTAL_CODE_REGEXP.test(internationalPostalCode);

/**
 * Checks that the provided Finnish postal code is correctly formatted.
 * @param finnishPostalCode the postal code to validate
 */
export const isValidFinnishPostalCode = (finnishPostalCode: string) =>
  !isEmpty(finnishPostalCode) && FINNISH_POSTAL_CODE_REGEXP.test(finnishPostalCode);

/**
 * Checks that the provided Swedish premise identifier is correctly formatted.
 * @param swedishPremiseId the premise id to validate
 */
export const isValidSwedishPremiseId = (swedishPremiseId: string) =>
  !isEmpty(swedishPremiseId) &&
  SWEDISH_PREMISE_ID_REGEXP.test(swedishPremiseId) &&
  hasValidSwedishPremiseChecksum(swedishPremiseId);

/**
 * Checks that the provided password is correctly formatted.
 * @param password the password to validate
 */
export const isValidPassword = (password: string) => {
  return PASSWORD_BR_16.test(password);
};

/**
 * Checks that the provided street name is correctly formatted
 * for both premise and invoice details
 * @param streetName the street name to validate
 */
export const isValidStreetName = (streetName: string) => {
  return STREET_NAME_INVOICE_REGEXP.test(streetName);
};

/**
 * Checks that the provided care of address is correctly formatted.
 * @param careOfAddress the careOfAddress to validate
 */
export const isValidCareOfAddress = (careOfAddress: string) => {
  return CARE_OF_ADDRESS_REGEX.test(careOfAddress);
};

/**
 * Checks that the provided Swedish or Finnish postal town is correctly formatted.
 * @param postalTown the postal town to validate
 */
export const isValidSwedishFinnishPostalTown = (postalTown: string) =>
  !isEmpty(postalTown) && SWEDISH_FINNISH_POSTAL_TOWN_REGEXP.test(postalTown);

/**
 * Validates a Grid Area against a list of Grid Area Id found through below source.
 * @see [Grid Area (Whole Sweden)](https://mimer.svk.se/NetworkArea/Search)
 * @param gridAreaId The grid area id to validate
 */
export const isValidGridAreaId = (gridAreaId: string) => {
  // @ts-ignore Keep as a list
  const GRID_AREA_ID = <const>[
    "AJG",
    "ALS",
    "AMS",
    "ALV",
    "ARD",
    "AJB",
    "ARV",
    "AVE",
    "AVR",
    "BEF",
    "BRG",
    "BGS",
    "BGO",
    "BEV",
    "BJR",
    "BKE",
    "BJL",
    "BJK",
    "BLE",
    "BLI",
    "BDN",
    "NBL",
    "BOO",
    "BHM",
    "BRL",
    "BRS",
    "BDL",
    "BMA",
    "BYG",
    "CBR",
    "DAL",
    "DAD",
    "DAP",
    "DAN",
    "DFS",
    "DOF",
    "DRV",
    "EBN",
    "EKO",
    "ESJ",
    "EBA",
    "EKG",
    "ESK",
    "FGA",
    "FBN",
    "FBG",
    "FLN",
    "FLO",
    "FPD",
    "FSB",
    "FLE",
    "FOB",
    "FBA",
    "FRV",
    "FMD",
    "GNF",
    "GAM",
    "GAB",
    "GDB",
    "GIV",
    "GTL",
    "GTP",
    "GBN",
    "GLG",
    "GVL",
    "GTA",
    "GBG",
    "HBO",
    "HLB",
    "HLD",
    "NHA",
    "HVI",
    "HMD",
    "HAM",
    "HMH",
    "HMM",
    "HMR",
    "HRS",
    "HVN",
    "HBY",
    "HDS",
    "HBG",
    "HLJ",
    "HJO",
    "HJM",
    "HOF",
    "HDL",
    "HDG",
    "HDV",
    "HUV",
    "HAL",
    "HAR",
    "HJD",
    "HJN",
    "HSD",
    "HRA",
    "HOG",
    "HOR",
    "ING",
    "JUJ",
    "JMT",
    "JML",
    "JKE",
    "JRA",
    "JPS",
    "JNK",
    "KAB",
    "KMR",
    "KBG",
    "KHN",
    "KSA",
    "KKA",
    "KST",
    "KHB",
    "KEM",
    "KND",
    "KKB",
    "KLK",
    "KPN",
    "KNP",
    "KON",
    "KOB",
    "KNS",
    "KBR",
    "KRF",
    "KRS",
    "KRH",
    "KRG",
    "KBA",
    "KUB",
    "KUV",
    "KVI",
    "KVM",
    "LHM",
    "LKA",
    "LER",
    "LRM",
    "LDG",
    "LDK",
    "LED",
    "LGR",
    "LDB",
    "LNK",
    "LEV",
    "LJV",
    "LBY",
    "LJD",
    "LMA",
    "LUL",
    "LAO",
    "LUN",
    "LYC",
    "LYX",
    "LYS",
    "MBT",
    "MAF",
    "MMO",
    "MLG",
    "MAI",
    "MSD",
    "MEL",
    "VFM",
    "MBY",
    "MOR",
    "MYN",
    "MDL",
    "MRP",
    "NRL",
    "NSF",
    "NOB",
    "NVS",
    "VFN",
    "NTE",
    "NSB",
    "NBR",
    "NYK",
    "NYD",
    "NHN",
    "NFO",
    "NKL",
    "NSO",
    "OBO",
    "OLS",
    "OSD",
    "ORV",
    "ORT",
    "OHN",
    "OXD",
    "PTE",
    "PIB",
    "RSJ",
    "RBY",
    "RSK",
    "RBF",
    "ROY",
    "SAH",
    "SHT",
    "SDV",
    "SIG",
    "SIH",
    "SJO",
    "SHD",
    "SJB",
    "SKA",
    "SKT",
    "SKF",
    "SKR",
    "SKP",
    "SBR",
    "SKL",
    "SNM",
    "SKV",
    "SKH",
    "SKN",
    "SKD",
    "SLD",
    "SMB",
    "SMN",
    "SML",
    "SMS",
    "SOT",
    "SFP",
    "ST1",
    "ST2",
    "ST3",
    "ST4",
    "STF",
    "SGD",
    "STH",
    "STO",
    "ARN",
    "SGS",
    "STU",
    "SRF",
    "SUR",
    "SUV",
    "SBV",
    "SWP",
    "SYM",
    "SYD",
    "SBK",
    "SFE",
    "STR",
    "SSJ",
    "SHN",
    "SDT",
    "SBG",
    "SBL",
    "TIB",
    "THM",
    "TLB",
    "TDN",
    "TNN",
    "TDO",
    "TOR",
    "TOS",
    "TNS",
    "TBG",
    "TRH",
    "TRS",
    "TUM",
    "TYS",
    "TYR",
    "TBY",
    "TRE",
    "TBA",
    "UVA",
    "UHN",
    "UMA",
    "UME",
    "UMD",
    "UMN",
    "UMO",
    "UPN",
    "UPS",
    "UPV",
    "UPO",
    "URM",
    "UPP",
    "VGD",
    "VAG",
    "VAB",
    "VAT",
    "VNB",
    "VAR",
    "VBG",
    "VBN",
    "VBS",
    "VDM",
    "VTL",
    "VGF",
    "VMA",
    "VBY",
    "VDN",
    "VIG",
    "VLB",
    "VMD",
    "VAL",
    "VML",
    "VMO",
    "VRO",
    "VAS",
    "VAD",
    "VBL",
    "VBR",
    "VBK",
    "VVI",
    "VLS",
    "VFV",
    "VAN",
    "VXO",
    "YST",
    "ALL",
    "ADL",
    "AKB",
    "ALM",
    "AML",
    "ANE",
    "ANN",
    "AMN",
    "ANG",
    "ARS",
    "ASL",
    "AVD",
    "ABB",
    "AHB",
    "OCK",
    "OLD",
    "OVK",
    "OFB",
    "OLN",
    "OHM",
    "OGO",
    "VFO",
  ];

  return includes(__, GRID_AREA_ID)(gridAreaId);
};

/**
 * Checks that provided date is a Swedish weekday.
 * @param date the date to validate
 */
export const isValidSwedishWeekday = (date: Date) => {
  return !isWeekend(date) && !isSwedishPublicHoliday(date);
};

/**
 * Checks that provided date is a Finnish weekday.
 * @param date the date to validate
 */
export const isValidFinnishWeekday = (date: Date) => {
  return !isWeekend(date) && !isFinnishPublicHoliday(date);
};
