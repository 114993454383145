import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
  CareOfAddressValidator,
  EmailAddressValidator,
  GridAreaIdValidator,
  MaxValidator,
  MaxDateValidator,
  MaxWeekValidator,
  MaxMonthValidator,
  MaxYearValidator,
  MinValidator,
  MinDateValidator,
  MinWeekValidator,
  MinMonthValidator,
  MinYearValidator,
  OrganizationNumberValidator,
  PasswordValidator,
  PersonalNumberValidator,
  PhoneNumberValidator,
  PostalCodeValidator,
  PostalTownValidator,
  PremiseIdValidator,
  StreetNameValidator,
  WeekdayValidator,
} from "./validators.directive";

@NgModule({
  declarations: [
    EmailAddressValidator,
    GridAreaIdValidator,
    MaxValidator,
    MaxDateValidator,
    MaxWeekValidator,
    MaxMonthValidator,
    MaxYearValidator,
    MinValidator,
    MinDateValidator,
    MinWeekValidator,
    MinMonthValidator,
    MinYearValidator,
    OrganizationNumberValidator,
    PasswordValidator,
    PersonalNumberValidator,
    PhoneNumberValidator,
    PremiseIdValidator,
    PostalCodeValidator,
    StreetNameValidator,
    CareOfAddressValidator,
    PostalTownValidator,
    WeekdayValidator,
  ],
  imports: [CommonModule],
  exports: [
    EmailAddressValidator,
    GridAreaIdValidator,
    MaxValidator,
    MaxDateValidator,
    MaxWeekValidator,
    MaxMonthValidator,
    MaxYearValidator,
    MinValidator,
    MinDateValidator,
    MinWeekValidator,
    MinMonthValidator,
    MinYearValidator,
    OrganizationNumberValidator,
    PasswordValidator,
    PersonalNumberValidator,
    PhoneNumberValidator,
    PremiseIdValidator,
    PostalCodeValidator,
    StreetNameValidator,
    CareOfAddressValidator,
    PostalTownValidator,
    WeekdayValidator,
  ],
})
export class ValidatorsModule {}
