/**
 * Performs a deep merge of `source` into `target`. Mutates `target` only but not its objects and arrays.
 */
// Allowing the use of 'any' type because the type 'object' is discouraged by eslint.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeDeep<T = any>(target: any, source: any): T {
  const isObject = (obj: unknown | unknown) => obj && typeof obj === "object";

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
