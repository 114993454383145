/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError } from "../functions";

/**
 * Decorator that checks that if a class property has a value and logs an error when not
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Required(): PropertyDecorator {
  return function (target: any, propertyKey: PropertyKey) {
    // Attaching the value on a private property of this ensures that
    // it can be set on the class instance as oppose to the prototype
    // Casting as string due to an issue in TypeScript: https://github.com/microsoft/TypeScript/issues/1863
    const symbol = Symbol("__Required__" + String(propertyKey)) as unknown as string;
    const instances = new Set<any>();
    Object.defineProperty(target, propertyKey, {
      get() {
        instances.add(this);
        return this[symbol];
      },
      set(value: any) {
        instances.add(this);
        this[symbol] = value;
      },
    });
    setTimeout(
      () => {
        instances.forEach((instance) => {
          if (instance[symbol] === undefined)
            logError(`${target.constructor.name}: The property '${String(propertyKey)}' is required but empty.`);
        });
      },
      // Allow for some time to pass before checking if the properties has a value
      1000
    );
  };
}
