import {
  getYear,
  getMonth,
  getISOWeek,
  getDate,
  endOfDay,
  nextMonday,
  setWeek,
  startOfDay,
  startOfMonth,
  endOfMonth,
  endOfISOWeek,
  endOfYear,
  startOfISOWeek,
  startOfYear,
} from "date-fns";
import finnishPublicHolidays from "finnish-holidays-js";
import { isPublicHoliday } from "swedish-holidays";

// TODO (stefan): Sophisticated types for HTML input strings.
// Currently not a viable option in TypeScript but might work better in later versions.
// Leaving them here for now.

// type YYYY = `20${0 | 1 | 2 | 3}${Digits}`;

type Digits = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/**
 * Years
 */
type YYYY = `${number}`;

/**
 * Months
 */
type MM = `0${Exclude<Digits, 0>}` | `1${0 | 1 | 2}`;

/**
 * Weeks
 */
type WW = `0${Exclude<Digits, 0>}` | `${1 | 2 | 3 | 4}${0 | Digits}` | `5${0 | 1 | 2}`;

/**
 * Days
 */
type DD = `0${Exclude<Digits, 0>}` | `${1 | 2}${0 | Digits}` | `3${0 | 1}`;

/**
 * Date presented as string, e.g. `2022-11-12`
 */
export type InputDate = `${YYYY}-${MM}-${DD}`;

/**
 * Week presented as string, e.g. `2022-W12`
 */
export type InputWeek = `${YYYY}-W${WW}`;

/**
 * Month presented as string, e.g. `2022-11`
 */
export type InputMonth = `${YYYY}-${MM}`;

/**
 * Month presented as string, e.g. `2022`
 */
export type InputYear = `${YYYY}`;

/**
 * Name of possible `HTMLInput` date strings types.
 */
export type InputName = "year" | "month" | "week" | "date";

/**
 * Types of possible `HTMLInput` date strings.
 */
export type InputType = InputDate | InputWeek | InputMonth | InputYear;

/**
 * Date units extracted from a `Date`
 */
export type InputDateUnits = {
  year: YYYY;
  month: MM;
  week: WW;
  date: DD;
};

/** Highest date supported by JavaScript Date object (100 000 000 days after unix epoch). */
export const HIGHEST_DATE = new Date(8640000000000000);

/** Lowest date supported by JavaScript Date object (100 000 000 days before unix epoch). */
export const LOWEST_DATE = new Date(-8640000000000000);

/** Parse a date format string into a `RegExp` pattern string. */
export function patternFromFormat(format: string): string {
  const grouped = format.match(/'[^']*'|(.)\1*/g);
  if (grouped)
    return grouped
      .map((phrase) => {
        if (/'([^']+)'/.test(phrase)) return phrase.replace(/'/g, "");
        if (/[Yy|Mm|Dd|Ww]/.test(phrase)) return `(?!0+$)\\d{${phrase.length}}`;
        return phrase;
      })
      .join("");
  return "";
}

/** Parse a `Date` object or date represented as a `number` into a `HTMLInput` string. */
export function inputFromDate<T extends InputType>(type: InputName, input?: string | Date | number | null): T | null {
  if (!input) return null;
  if (typeof input === "string") return input as T;
  const date = new Date(input);
  if (type === "date") return getInputDate(date) as T;
  if (type === "month") return getInputMonth(date) as T;
  if (type === "year") return getInputYear(date) as T;
  if (type === "week") return getInputWeek(date) as T;
  throw TypeError(`The provided type '${type}' is invalid. Should be 'date', 'month', 'year' or 'week'.`);
}

/** Parse a date string from a `HTMLInputElement` into a `Date` object. */
export function dateFromInput(input: InputType): Date {
  if (isInputDate(input)) return startOfDay(new Date(input));
  if (isInputMonth(input)) return startOfMonth(new Date(input));
  if (isInputYear(input)) return startOfYear(new Date(input));
  if (isInputWeek(input)) {
    const [year, week] = input.split("-W").map(Number);
    return startOfISOWeek(
      setWeek(nextMonday(new Date(year, 0, 4)), week, {
        // Start week on Monday (not Sunday which is 0)
        weekStartsOn: 1,
        // The day of January, which is always in the first week of the year
        firstWeekContainsDate: 4,
      })
    );
  }
  throw TypeError(
    `The provided input '${input}' is invalid. Should be of format 2022-01-01 for dates, 2022-01 for months, and 2022-W01 for weeks.`
  );
}

/** Parse a date string from a `HTMLInput` element into a `Interval` object. */
export function dateRangeFromInput<T extends InputType>(input: T): Interval {
  const start = dateFromInput(input);
  if (isInputDate(input)) return { start, end: endOfDay(start) };
  if (isInputMonth(input)) return { start, end: endOfMonth(start) };
  if (isInputYear(input)) return { start, end: endOfYear(start) };
  if (isInputWeek(input)) return { start, end: endOfISOWeek(start) };
  throw TypeError(
    `The provided input '${input}' is invalid. Should be of format 2022-01-01 for dates, 2022-01 for months, and 2022-W01 for weeks.`
  );
}

/** Converts UTF date to browser local time zone.  */
export function getLocalDate(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

/** Parse ´Date´ object to a `HTMLInput` date string. */
export function getInputDate(date: Date): InputDate {
  const dateUnits = getInputDateUnits(startOfDay(date));
  return `${dateUnits.year}-${dateUnits.month}-${dateUnits.date}`;
}

/** Parse ´Date´ object to a `HTMLInput` week string. */
export function getInputWeek(date: Date): InputWeek {
  const dateUnits = getInputDateUnits(startOfISOWeek(date));
  return `${dateUnits.year}-W${dateUnits.week}`;
}

/** Parse ´Date´ object to a `HTMLInput` month string. */
export function getInputMonth(date: Date): InputMonth {
  const dateUnits = getInputDateUnits(startOfMonth(date));
  return `${dateUnits.year}-${dateUnits.month}`;
}

/** Parse ´Date´ object to a `HTMLInput` year string. */
export function getInputYear(date: Date): InputYear {
  const dateUnits = getInputDateUnits(startOfYear(date));
  return `${dateUnits.year}`;
}

/** Whether the provided input is of type `InputDate` */
export function isInputDate(input: string): input is InputDate {
  return /^\d{4}-\d{2}-\d{2}$/.test(input);
}

/** Whether the provided input is of type `InputWeek` */
export function isInputWeek(input: string): input is InputWeek {
  return /^\d{4}-W\d{2}$/.test(input);
}

/** Whether the provided input is of type `InputMonth` */
export function isInputMonth(input: string): input is InputMonth {
  return /^\d{4}-\d{2}$/.test(input);
}

/** Whether the provided input is of type `InputYear` */
export function isInputYear(input: string): input is InputYear {
  return /^\d{4}$/.test(input);
}

/** Whether the provided input is of type `InputType` */
export function isInputType(input: unknown): input is InputType {
  return typeof input === "string" && (isInputDate(input) || isInputWeek(input) || isInputMonth(input) || isInputYear(input));
}

/** Whether the provided date is a Swedish public holiday. */
export function isSwedishPublicHoliday(date: Date) {
  return isPublicHoliday(date);
}

/** Whether the provided date is a Finnish public holiday. */
export function isFinnishPublicHoliday(date: Date) {
  const dateUnit = getDateUnits(date);
  const publicHolidays = finnishPublicHolidays.month(dateUnit.month, dateUnit.year, true);
  return publicHolidays.some((publicHoliday) => publicHoliday.day === dateUnit.date);
}

/** Extracts date units (year, month, date, week) from `Date` object. */
function getDateUnits(date: Date) {
  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    week: getISOWeek(date),
    date: getDate(date),
  };
}

/** Extracts input date units (year, month, date, week) from `Date` object. */
function getInputDateUnits(date: Date): InputDateUnits {
  const dateUnits = getDateUnits(date);
  return {
    year: dateUnits.year.toString() as YYYY,
    month: dateUnits.month.toString().padStart(2, "0") as MM,
    week: dateUnits.week.toString().padStart(2, "0") as WW,
    date: dateUnits.date.toString().padStart(2, "0") as DD,
  };
}
