/**
 * Computes the nth percentile of the given data (array elements) along the specified axis.
 */
export function getPercentile(numbers: Array<number>, percentile: number): number {
  if (percentile < 0 || percentile > 100) {
    throw new RangeError("Percentile value must be between 0 and 100");
  }
  numbers.sort((pre, cur) => pre - cur); // sort the array in ascending order
  const index = (percentile / 100) * (numbers.length - 1); // calculate the index of the percentile
  const lowerIndex = Math.floor(index); // calculate the lower index
  const upperIndex = Math.ceil(index); // calculate the upper index
  if (lowerIndex === upperIndex) {
    // if the index is an integer, return the corresponding value
    return numbers[lowerIndex];
  }
  const lowerValue = numbers[lowerIndex]; // get the value at the lower index
  const upperValue = numbers[upperIndex]; // get the value at the upper index
  return lowerValue + (index - lowerIndex) * (upperValue - lowerValue); // interpolate between the two values
}
