import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Type } from "@angular/core";
import { TestBed, TestComponentRenderer, TestModuleMetadata } from "@angular/core/testing";
import { Store } from "@ngxs/store";

import { Environment, ENVIRONMENT } from "./elements";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// A dummy custom element to be used as the root element and component host view
class RootElement extends HTMLElement {
  constructor() {
    super();
  }
}

/**
 * A DOM based implementation of the `TestComponentRenderer` for custom elements.
 *
 * This class is used in place of the `DOMTestComponentRenderer` which creates a div element with a unique id in the document body,
 * and then creates a component with that div element as its application host view. This will not work with custom elements,
 * because the custom element is defined by its tag name.
 *
 * By overriding the `DOMTestComponentRenderer`, this class makes sure that the root element is a custom element and that the test component
 * is created inside that element. This allows for both testing the component as a `ComponentFixture` and still use `slot` elements
 * and other web component utilities.
 */
@Injectable()
class DOMTestElementRenderer extends TestComponentRenderer {
  constructor(@Inject(DOCUMENT) private document: Document, @Inject("tagName") private tagName: string | undefined) {
    super();
  }

  // This method will be invoked by the `TestBed` `createComponent` method before the component is created,
  // and the `ComponentFixture` is returned
  insertRootElement(rootElementId: string) {
    if (!this.tagName) throw new Error(`[ Vattenfall Elements ] – No tag name provided. Could not insert custom root element.`);

    if (!customElements.get(this.tagName)) {
      // Define the `RootElement` as a custom element in the browser custom elements registry
      customElements.define(this.tagName, RootElement);
    }
    const rootElement = this.document.createElement(this.tagName);
    rootElement.id = rootElementId;
    this.document.body.appendChild(rootElement);
  }
}

/**
 * `StateProp` is the type describing the global state of the application
 */
export type StateProp = { [state: string]: unknown };

/**
 * `ActionProp` is the type describing a state action and its payload
 */
export type ActionProp = new (...args: unknown[]) => unknown;

/**
 * `SlotableElementProp` is the type describing a slotable element object
 */
export type SlotableElementProp = {
  value?: string;
  tagName?: string;
  attr?: { [attributeName: string]: string };
};

/**
 * `SlotableElementProps` is the type describing an object of slotable elements
 */
export type SlotableElementProps = {
  [slotRef: string]: string | SlotableElementProp;
};

/**
 * `ElementMetadata` is the type describing metadata to use when bootstraping an Vattenfall Element
 */
export type ElementMetadata = {
  environment: Optional<Environment, "endpoints" | "livemode" | "language" | "region">;
  state?: StateProp;
  slotableElements?: SlotableElementProps | string;
};

let elementsTestBed: ElementsTestBed;

export function getElementsTestBed(): ElementsTestBed {
  return (elementsTestBed = elementsTestBed || new ElementsTestBed());
}
/**
 * The `ElementsTestBed` is the primary API for writing unit tests for elements, components and services in the Vattenfall Elements framework.
 *
 * I inherits the same functionality as the standard Angular TestBed, but extends it with methods for testing custom elements and other related web component technologies.
 */
export class ElementsTestBed {
  private store!: Store;
  private state!: StateProp;
  private environment!: Environment;
  private slotableElements!: { [slotRef: string]: HTMLElement } | string;

  /**
   * Creates a component as a child of a custom element and return that component as a `ComponentFixture`.
   *
   * Please note that similarly to the `createComponent` method, the first time this method is invoked the environment for unit testing stays fixed to that specific configuration,
   * and that this method returns a Promise since the custom elements definition is an async process.
   *
   * @param component The component to create
   */
  static async createElement<T>(component: Type<T>) {
    const componentSelector = ElementsTestBed.componentSelector(component);
    // This override the `TestComponentRenderer` which is responsible for creating
    // a root element which the `createComponent` method will use to attach the component into,
    // with a custom built renderer for custom elements
    TestBed.overrideProvider(TestComponentRenderer, {
      useFactory: (document: Document) => new DOMTestElementRenderer(document, componentSelector),
      deps: [DOCUMENT],
    });
    // This sets the inital state for the element
    if (getElementsTestBed().state) ElementsTestBed.configureState(getElementsTestBed().state);
    // Create a component fixture with the `DOMTestElementRenderer` now intercepting root element creating step
    const fixture = TestBed.createComponent(component);
    // Append the slotable elements as children to the custom element
    if (getElementsTestBed().slotableElements)
      if (typeof getElementsTestBed().slotableElements === "string") {
        fixture.nativeElement.innerHTML = getElementsTestBed().slotableElements;
      } else
        Object.values(getElementsTestBed().slotableElements).forEach((slotableElement) =>
          fixture.nativeElement.appendChild(slotableElement)
        );
    // Await the custom element to be defined in the browser custom elements registry
    // and then return its component fixture
    if (componentSelector) await customElements.whenDefined(componentSelector);
    return fixture;
  }

  /**
   * Returns a component's selector from its class annotation
   * @param component The component to get the selector from
   */
  static componentSelector(component: Type<unknown>) {
    const annotations = Object.getOwnPropertyDescriptor(component, "__annotations__");
    if (annotations) return annotations.value[0].selector;
    else return undefined;
  }

  /**
   * Takes a list of slotable element objects and generates a list of real HTML elements.
   *
   * **Usage**
   *
   * ```html
   * <!--
   *  Default: "someSlotableElementRef": "Some slotted value"
   * -->
   * <span slot="someSlotableElementRef">Some slotted value</span>
   * <!--
   *  Custom tag: "someSlotableElementRef": { tagName: "header", value: "Some slotted value" }
   * -->
   * <header slot="someSlotableElementRef">Some slotted value</header>
   * <!--
   *  Custom attribute: "someSlotableElementRef": { tagName: "img", attr: { src: "/path/to/image.png" } }
   * -->
   * <img slot="someSlotableElementRef" srf="/path/to/image.png"/>
   * ```
   *
   * @param slotableElements The slotable objects to generate HTML elements from
   */
  static generateSlotableElements(slotableElementProps: SlotableElementProps): { [slotRef: string]: HTMLElement } {
    const slotableElements: Record<string, HTMLElement> = {};
    for (const slotRef in slotableElementProps) {
      const slotValueOrObject = slotableElementProps[slotRef];
      // If the value is a string then use <span>
      const tagName = typeof slotValueOrObject === "string" || !slotValueOrObject.tagName ? "span" : slotValueOrObject.tagName;
      const attributes =
        typeof slotValueOrObject !== "string" && slotValueOrObject.attr ? Object.entries(slotValueOrObject.attr) : [];
      const value = typeof slotValueOrObject === "string" ? slotValueOrObject : slotValueOrObject.value || "";
      const slotableElement = document.createElement(tagName);
      attributes.forEach(([attributeName, attributeValue]) => slotableElement.setAttribute(attributeName, attributeValue));
      slotableElement.setAttribute("slot", slotRef);
      slotableElement.textContent = value;
      slotableElements[slotRef] = slotableElement;
    }
    return slotableElements;
  }

  /**
   * Extracts slot elements from a HTML string or HTMLElement
   * @param htmlStringOrElement The HTML string or HTMLElement to extract the slots elements from
   */
  static extractSlotElements(htmlStringOrElement: string | HTMLElement): { [slotRef: string]: HTMLSlotElement } {
    const slotElements: Record<string, HTMLSlotElement> = {};
    const element = document.createElement("template");
    if (typeof htmlStringOrElement === "string") element.innerHTML = htmlStringOrElement;
    else element.appendChild(htmlStringOrElement);
    element.querySelectorAll("slot").forEach((slotElement) => {
      const slotRef = slotElement.getAttribute("name") || "__empty__";
      slotElements[slotRef] = slotElement;
    });
    return slotElements;
  }

  /**
   * Sets the test modules boostrap configuration options.
   *
   * **Environment**
   *
   * This would be useful when testing an element's behaviour in for example different locales.
   *
   * The config argument reflects the same as when the application config used when bootstraping the application i.e. `new Elements({ ... })`; however,
   * by providing the environment object you may overwrite any of these arguments if needed, for example when testing that a service throws an error
   * if an incorrect URL path has been provided.
   *
   * **State**
   *
   * This is useful when setting an initial state of the application before components are created and loaded in view.
   * Please note that you need to provide the registered state name as key, as this method will set the entire global state.
   *
   * **Slots**
   *
   * This allows you to pass slotable elements to the element.
   *
   * @param elementDef The element's bootstraping configurations
   */
  static configureElements(elementDef: ElementMetadata) {
    // Set the environment configs
    {
      getElementsTestBed().environment = {
        livemode: false,
        locale: elementDef.environment.locale,
        language: elementDef.environment.language
          ? elementDef.environment.language
          : elementDef.environment.locale.split("-")[0].toLowerCase(),
        region: elementDef.environment.region
          ? elementDef.environment.region
          : elementDef.environment.locale.split("-")[1].toLowerCase(),
        endpoints: elementDef.environment.endpoints
          ? elementDef.environment.endpoints
          : {
              selfServiceApi: "/elements",
              acquisitionApi: "/api/acquisition",
            },
        trackingId: elementDef.environment.trackingId,
        viewId: elementDef.environment.viewId,
      } as Environment;
    }

    // Set slot elements
    if (elementDef.slotableElements) {
      if (typeof elementDef.slotableElements !== "string")
        getElementsTestBed().slotableElements = ElementsTestBed.generateSlotableElements(elementDef.slotableElements);
      else getElementsTestBed().slotableElements = elementDef.slotableElements;
    }

    // Sets the inital state
    if (elementDef.state) {
      getElementsTestBed().state = elementDef.state;
    }

    return ElementsTestBed;
  }

  /**
   * Sets or resets the state of the application.
   *
   * This is useful when preparing the state for the next operation.
   * Please note that you need to provide the registered state name as key, as this method will set the entire global state.
   *
   * The state will not be patched, but reset everytime this method is called.
   *
   * @param state The global state to set
   */
  static configureState(state: StateProp) {
    // Should save the state and take from that when the app is initialized
    getElementsTestBed().store = getElementsTestBed().store || TestBed.inject(Store);
    getElementsTestBed().store.reset(state);
  }

  /**
   * Dispatches a state action and returns a promise which resolves once the dispatch has completed.
   * @param action The action to dispatch
   */
  static async dispatchAction(action: ActionProp) {
    await getElementsTestBed().store.dispatch(action).toPromise();
  }

  /**
   * Allows overriding default providers, directives, pipes, modules of the test injector, which are defined in test_injector.js
   */
  static configureTestingModule(moduleDef: TestModuleMetadata) {
    return TestBed.configureTestingModule({
      ...{
        // TODO (stefan): Add LOCALE provider as well in order to test elements using different locale settings
        providers: [
          {
            provide: ENVIRONMENT,
            useValue: getElementsTestBed().environment,
          },
        ].concat(moduleDef.providers || []),
      },
      ...moduleDef,
    });
  }
}

declare global {
  namespace jest {
    interface Matchers<R> {
      toBeAssigned(): R;
      toBeAssignedOrDuplicated(): R;
    }
  }
}

/**
 * For checking whether an HTMLSlotElement has any nodes assigned to it
 */
if (typeof window === "undefined")
  expect.extend({
    toBeAssigned(received: HTMLSlotElement) {
      if (received.assignedNodes().length) {
        return {
          message: () => `expected HTMLSlotElement '${received.name}' not to have assigned nodes`,
          pass: true,
        };
      } else {
        return {
          message: () => `expected HTMLSlotElement '${received.name}' to have assigned nodes`,
          pass: false,
        };
      }
    },
  });

/**
 * For checking whether an HTMLSlotElement has any nodes assigned to it or if the slot element has been duplicated
 */
if (typeof window === "undefined")
  expect.extend({
    toBeAssignedOrDuplicated(received: HTMLSlotElement) {
      let duplicatedSlot;
      if (!received.assignedNodes().length) {
        const slottableElements = Array.from((received.getRootNode() as ShadowRoot).host.children);
        duplicatedSlot = Array.from(received.children).some((childElement) =>
          slottableElements.some((slottableElement) => childElement.innerHTML === slottableElement.innerHTML)
        );
      }
      if (received.assignedNodes().length || duplicatedSlot) {
        return {
          message: () => `expected HTMLSlotElement '${received.name}' not to have assigned nodes`,
          pass: true,
        };
      } else {
        return {
          message: () => `expected HTMLSlotElement '${received.name}' to have assigned nodes`,
          pass: false,
        };
      }
    },
  });
