import { Inject, Injectable, Optional } from "@angular/core";
import { Actions } from "@ngxs/store";
import { filter, map } from "rxjs/operators";

import { ENVIRONMENT, Environment } from "../elements";

/**
 * Declaring custom attribute `dataLayer` on the `Window` object.
 */
declare global {
  interface Window {
    /**
     * The data layer is an array of objects used to pass information from a website to your Google Tag Manager container.
     *
     * It is essentially a message bus for web site data such as product data, user interaction data, marketing campaign information, transaction data, etc. and the web site external data processing services.
     */
    dataLayer: EventLike[];
  }
}

/**
 * The type describing an event like object as dispatched to the data layer
 */
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type EventLike = { event?: string; [key: string]: Exclude<any, Function> };

/**
 * Dispatches events through the data layer to a Google Tag Manager container.
 *
 * The data layer is an array of objects used to pass information from a website to external services.
 *
 * It is essentially a message bus for web site data such as product data, user interaction data, marketing campaign information, transaction data, etc. and the web site external data processing services.
 */
@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  constructor(
    @Optional()
    @Inject(ENVIRONMENT)
    protected environment: Environment,
    @Optional()
    private actions?: Actions
  ) {
    // Sets a data layer property on the window object
    window.dataLayer = window.dataLayer || [];
    // Dispatch an elements initialized event
    this.dispatch({ event: "Elements@Initialized", ...environment });
    // If actions has been provided then tap into the actions stream
    if (this.actions)
      // Dispatch every time an action has been dispatched
      this.actions
        .pipe(
          // Ignore failed or canceled actions
          filter(({ status }) => status === "SUCCESSFUL"),
          // Ignore system actions
          filter(({ action }) => !action.constructor.type.startsWith("@@")),
          // Map to a standard event object
          map(({ action }) => {
            // Turns an action type string and turns it into an event string: [ TheElementClassName ] NameOfAction > TheElementClassName.NameOfAction
            const eventName = action.constructor.type.replace(/ /g, "").replace("[", "").replace("]", ".");
            return { event: `Elements@${eventName}`, ...action };
          }),
          // Ignore empty events
          filter((event) => event != null)
          // Dispatch action as event
        )
        .subscribe(this.dispatch);
  }

  /**
   * Data model for dispatching enhanced ecommerce field objects
   */
  public ecommerce = new Ecommerce();

  /**
   * Dispatches an event to the data layer
   * @param event An event object (should typically have an `event` property but it is not required)
   */
  public dispatch(event: EventLike) {
    // Prefix the event name with Elements namespace
    if (event.event && !event.event.startsWith("Elements@")) event.event = `Elements@${event.event}`;
    // Dispatch the event to the data layer
    window.dataLayer.push(event);
    // Return the service to enable method chaining
    return this as unknown as DataLayerService;
  }
}

/**
 * The interface describing a custom dimensions that can be sent along a Google Analytics event.
 * Must be named `dimension` followed by an integer value, i.e. `dimension1`.
 */
export interface Dimension {
  [dimensionN: string]: unknown;
}

/**
 * The interface describing a custom metric that can be sent along a Google Analytics event.
 * Must be named `metric` followed by an integer value, i.e. `metric1`.
 */
export interface Metric {
  [metricN: string]: unknown;
}

/**
 * The interface describing information about an ecommerce related action that took place.
 */
export interface EcommerceAction {
  /**
   * Transaction ID
   */
  id?: string;
  /**
   * Affiliation from which this transaction accurred
   */
  affiliation?: string;
  /**
   * Total revenue associated with the transaction
   */
  revenue?: number;
  /**
   * Tax associated with the transaction
   */
  tax?: number;
  /**
   * Shipping cost associated with the transaction
   */
  shipping?: number;
  /**
   * Transaction coupon redeemed with transaction
   */
  coupon?: string;
  /**
   * list that the associated product belongs to
   */
  list?: string;
  /**
   * Number representing step in the checkout process
   */
  step?: number;
  /**
   * Additional field for checkout and checkout_option actions that can describe option information on the checkout page
   */
  option?: string;
}

/**
 * The interface describing information about a product that has been viewed
 */
export interface EcommerceImpression extends Partial<Dimension & Metric> {
  /**
   * Product ID or SKU
   */
  id: string;
  /**
   * Name of the product
   */
  name: string;
  /**
   * List or collection to which the product belongs
   */
  list?: string;
  /**
   * Brand associated with the product
   */
  brand?: string;
  /**
   * Category to which product belongs
   */
  category?: string;
  /**
   * Variant of the product
   */
  variant?: string;
  /**
   * Product's position in a list or collection
   */
  position?: number;
  /**
   * Price of the product
   */
  price?: number;
}

/**
 * The interface describing information about individual products that has been viewed, added to the shopping cart, etc.
 */
export interface EcommerceProduct extends Partial<Dimension & Metric> {
  /**
   * The product ID or SKU
   */
  id: string;
  /**
   * The name of the product
   */
  name: string;
  /**
   * The brand associated with the product
   */
  brand?: string;
  /**
   * The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
   */
  category?: string;
  /**
   * The variant of the product
   */
  variant?: string;
  /**
   * The price of a product
   */
  price?: number;
  /**
   * The quantity of a product
   */
  quantity?: number;
  /**
   * The coupon code associated with a product (e.g. SUMMER_SALE13)
   */
  coupon?: string;
  /**
   * 	The product's position in a list or collection
   */
  position?: number;
}

/**
 * The interface describing information about promotion that has been viewed.
 */
export interface EcommercePromotion extends Partial<Dimension & Metric> {
  /**
   * Promotion ID
   */
  id: string;
  /**
   * Name of the promotion
   */
  name: string;
  /**
   * Creative associated with the promotion
   */
  creative?: string;
  /**
   * Position of the creative
   */
  position?: number;
}

/**
 * `Ecommerce` specify how to interpret product and promotion data that you send to Google Analytics.
 */
class Ecommerce {
  /**
   * Holds the last pushed impression
   */
  public impressionFieldObject?: EcommerceImpression;
  /**
   * Holds the last pushed product
   */
  public productFieldObject?: EcommerceProduct;
  /**
   * Holds the last pushed promotion
   */
  public promotionFieldObject?: EcommercePromotion;

  /**
   * An impression of a for one or more products.
   * @param impression Product impression field object
   */
  impression(impression: EcommerceImpression) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Impression",
      ecommerce: {
        impressions: [impression],
      },
    });
    this.impressionFieldObject = impression;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * A click on a product or product link for one or more products.
   * @param product Product field object
   * @param action Action field object
   */
  click(product: EcommerceProduct, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Click",
      ecommerce: {
        click: {
          actionField: action,
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * A view of product details.
   * @param product Product field object
   * @param action Action field object
   */
  detail(product: EcommerceProduct, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Detail",
      ecommerce: {
        detail: {
          actionField: action,
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * Adding one or more products to a shopping cart.
   * @param product Product field object
   * @param action Action field object
   */
  add(product: EcommerceProduct, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Add",
      ecommerce: {
        add: {
          actionField: action,
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * Remove one or more products from a shopping cart.
   * @param product Product field object
   * @param action Action field object
   */
  remove(product: EcommerceProduct, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Remove",
      ecommerce: {
        remove: {
          actionField: action,
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * Initiating the checkout process for one or more products.
   * @param product Product field object
   * @param action Action field object
   */
  checkout(product: EcommerceProduct, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Checkout",
      ecommerce: {
        checkout: {
          actionField: action,
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * Sending the option value for a given checkout step.
   * @param action Action field object
   */
  checkoutOption(action: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.CheckoutOption",
      ecommerce: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        checkout_option: {
          actionField: action,
        },
      },
    });
  }

  /**
   * The sale of one or more products.
   * @param product Product field object
   * @param transactionId The order transaction identifier
   * @param action Action field object
   */
  purchase(product: EcommerceProduct, transactionId: string | number, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Purchase",
      ecommerce: {
        purchase: {
          actionField: {
            ...action,
            id: transactionId,
          },
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * The refund of one or more products.
   * @param product Product field object
   * @param transactionId The order transaction identifier
   * @param action Action field object
   */
  refund(product: EcommerceProduct, transactionId: string | number, action?: EcommerceAction) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.Refund",
      ecommerce: {
        refund: {
          actionField: {
            ...action,
            id: transactionId,
          },
          products: [product],
        },
      },
    });
    this.productFieldObject = product;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * A click on an internal promotion.
   * @param promotion Promotion field object
   */
  promoClick(promotion: EcommercePromotion) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.PromoClick",
      ecommerce: {
        promoClick: {
          promotions: [promotion],
        },
      },
    });
    this.promotionFieldObject = promotion;
    return Ecommerce as unknown as Ecommerce;
  }

  /**
   * An impression of an internal promotion.
   * @param promotion Promotion field object
   */
  promoView(promotion: EcommercePromotion) {
    window.dataLayer.push({
      event: "Elements@Ecommerce.PromoView",
      ecommerce: {
        promoView: {
          promotions: [promotion],
        },
      },
    });
    this.promotionFieldObject = promotion;
    return Ecommerce as unknown as Ecommerce;
  }
}
