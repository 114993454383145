import { fromEvent } from "rxjs";
import { debounceTime, finalize, take } from "rxjs/operators";

/**
 * Video util that will stop playing a video after specified number of times. This is useful for restricting playback of
 * autoplaying and looping background videos. Allowing indefinite looping will have implications for energy consumption,
 * as it might prevent screens from going to sleep.
 * @param videoElement
 * @param totalLoopCount
 */
export const limitVideoLoopCount = (videoElement?: HTMLVideoElement, totalLoopCount = 10) => {
  if (!videoElement) return;
  fromEvent(videoElement, "seeked")
    .pipe(
      debounceTime(500),
      take(totalLoopCount),
      finalize(() => {
        videoElement.pause();
        videoElement.currentTime = 0;
        videoElement.removeAttribute("loop");
      })
    )
    .subscribe();
};
