import { Inject, Pipe, PipeTransform } from "@angular/core";
// import { englishDateFnsLocale, finishDateFnsLocale, formatDistance, swedishDateFnsLocale } from "@vattenfall/util";

import { ENVIRONMENT, Environment } from "../elements/elements";

interface RelativeTimeFormatType {
  unit: Intl.RelativeTimeFormatUnit;
  divisor: number;
}
/**
 * Pipe for formatting a date to a distance from now.
 * The return strings will be in the language specified by the environment the app is running in.
 * It uses Intl.RelativeTimeFormat under the hood.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RelativeTimeFormat
 * Usage:
 * <span> {{ date | relativeTimeFormat: dateTo }} </span>
 * @param dateFrom The date from which the distance is calculated
 * @param dateTo The date to which the distance is calculated
 * @param unit The unit in which the distance should be returned. If not specified, the unit will be automatically determined.
 * @returns A string describing the distance between the two dates in a human readable format
 * @example "2 days ago", "4 minutes ago"
 * @example "för 2 dagar sedan", "för 4 minuter sedan"
 * @example "2 päivää sitten", "4 minuuttia sitten"
 */
@Pipe({
  name: "relativeTimeFormat",
})
export class RelativeTimeFormatPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}

  transform(dateFrom: Date | string, dateTo: Date = new Date(), unit?: Intl.RelativeTimeFormatUnit): string | null {
    try {
      if (typeof dateFrom === "string") dateFrom = new Date(dateFrom);
    } catch (error) {
      console.error("Invalid date format in pipe 'dateFormatDistance'.", error);
      return null;
    }
    if (dateFrom == null || dateTo == null) return null;
    return this.calculateTimeDifference(dateFrom, dateTo, unit);
  }

  calculateTimeDifference(dateFrom: Date, dateTo: Date, unit?: Intl.RelativeTimeFormatUnit): string {
    const locale = this.environment.locale;
    const format = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

    const distanceInSeconds = Math.round((dateFrom.getTime() - dateTo.getTime()) / 1000);

    const timeUnits: Array<RelativeTimeFormatType> = [
      { unit: "year", divisor: 31536000 },
      { unit: "month", divisor: 2592000 },
      { unit: "week", divisor: 604800 },
      { unit: "day", divisor: 86400 },
      { unit: "hour", divisor: 3600 },
      { unit: "minute", divisor: 60 },
      { unit: "second", divisor: 1 },
    ];
    // If the user has specified in which format the distance should be returned, use that format
    if (unit) {
      const matchingUnit = timeUnits.find((unitItem) => unitItem.unit === unit);
      if (matchingUnit) {
        const { divisor } = matchingUnit;
        const value = Math.round(distanceInSeconds / divisor);
        return format.format(value, unit);
      } else {
        throw new Error(`Invalid unit '${unit}' specified in pipe 'relativeTimeFormat'.`);
      }
    }

    // Otherwise find the most appropriate format
    for (const unitInfo of timeUnits) {
      if (Math.abs(distanceInSeconds) >= unitInfo.divisor) {
        const value = Math.round(distanceInSeconds / unitInfo.divisor);
        const unit: Intl.RelativeTimeFormatUnit = unitInfo.unit;
        return format.format(value, unit);
      }
    }

    // Default return value in seconds
    return format.format(distanceInSeconds, "second");
  }
}
