/**
 * Converts an RGB color array to a hex color code.
 *
 * @param rgb An RGB color array.
 * @returns A hex color code.
 */
export function rgbToHex(rgb: number[]): string {
  return (
    "#" +
    rgb
      .map((value) => Math.max(0, Math.min(255, Math.round(value))))
      .map((value) => value.toString(16).padStart(2, "0"))
      .join("")
  );
}
