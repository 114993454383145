import { enableProdMode, StaticProvider } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { createElementsClass, Elements } from "@vattenfall/core";

import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

// Callback function invoked once static providers has been resolved
async function onStaticProvidersResolved(staticProviders: StaticProvider[]) {
  const bootstrapModule = await import("./app/app.module").then((appModule) => appModule.AppModule);
  // Bootstrap module
  return platformBrowserDynamic(staticProviders).bootstrapModule(bootstrapModule);
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/ban-types
    ElementsNext: typeof Elements;
  }
}

// Setting the class to a property of the window object makes it
// accessible from outside this module
window.ElementsNext = createElementsClass<unknown>(onStaticProvidersResolved, environment.production);
