/**
 * Video accessibility util that will stop playing a video if the "prefers-reduced-motion" media feature evaluates to true.
 * Refer to https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion for more information.
 * @param videoElement
 */
export const applyReducedMotion = (videoElement?: HTMLVideoElement) => {
  if (!videoElement) return;
  if (window.matchMedia("(prefers-reduced-motion)").matches) {
    videoElement.pause();
    videoElement.currentTime = 0;
    videoElement.removeAttribute("autoplay");
    videoElement.removeAttribute("loop");
    return;
  }
};
