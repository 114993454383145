import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { coerceNumberProperty } from "@vattenfall/util";

/**
 * Takes either a date object or an integer value indicating a specific month of the year and return the name of that month. The unit pipe will produce different outputs depending on the locale of current runtime environment.
 *
 * ### Usage
 *
 * **With a integer value**
 *
 * ```html
 * input: 3 | month
 * output: Mars
 * ```
 *
 * **With a date object**
 *
 * ```html
 * input: date | month
 * output: Mars
 * ```
 */
@Pipe({
  name: "month",
})
export class MonthPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  /**
   * @param month The month represented as a integer value or date object to return.
   */
  transform(month: number | string | null | undefined | Date): string | null {
    if (month == null || month === "") return null;

    if (month instanceof Date) return this.datePipe.transform(month, "LLLL");

    if (![1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(coerceNumberProperty(month)))
      throw new Error(
        `[ Vattenfall Elements ] Invalid pipe argument for pipe 'month'. The provided month '${month}' is invalid, must be an integer in the range of 1 to 12.`
      );

    const date = new Date(0);
    date.setMonth(coerceNumberProperty(month), 0);

    return this.datePipe.transform(date, "LLLL");
  }
}
