/**
 * @todo Break out each method of `ElementsValidators` into a separate function to optimize the code for tree-shaking.
 * @todo The grid area ID validator should have one variation for Finland and one for Sweden.
 * @todo Handle errors using global error handler.
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import {
  isValidAgeRange,
  isValidFinnishBusinessNumber,
  isValidFinnishEmailAddress,
  isValidFinnishPersonalNumber,
  isValidFinnishPhoneNumber,
  isValidFinnishPostalCode,
  isValidGridAreaId,
  isValidInternationalPostalCode,
  isValidPassword,
  isValidSwedishBusinessNumber,
  isValidSwedishEmailAddress,
  isValidSwedishPersonalNumber,
  isValidSwedishPhoneNumber,
  isValidSwedishPostalCode,
  isValidSwedishPremiseId,
  isValidStreetName,
  isValidCareOfAddress,
  isValidSwedishFinnishPostalTown,
  isValidSwedishWeekday,
  isValidFinnishWeekday,
  isAfter,
  isBefore,
  dateFromInput,
  InputType,
  isInputType,
  coerceDateProperty,
  isEqual,
  startOfDay,
} from "@vattenfall/util";

import { Region } from "../elements/elements";

/**
 * Utility to check if the provided value for a form control is empty or null.
 * @param value - The value to be checked.
 * @returns `true` if the value is null or an empty string, otherwise `false`.
 */
function isEmptyInputValue(value: unknown): boolean {
  return value == null || (typeof value === "string" && value.length === 0);
}

/**
 * A collection of validators specifically built for Vattenfall Elements.
 *
 * Each validator ensures that the provided data adheres to specific regional patterns
 * or requirements, such as Swedish and Finnish phone numbers, postal codes, etc.
 */
export class ElementsValidators {
  /**
   * Validator that checks if the control's value corresponds to a properly formatted email address
   * with an official domain extension based on the specified region.
   *
   * @param region Specifies the region ('se' or 'fi') for which the validation applies.
   * @returns An error map with the `emailAddress` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const controlSE = new FormControl('firstname.lastname@gmail.com', ElementsValidators.emailAddress("se"));
   * console.log(controlSE.errors); // { emailAddress: true } or null
   *
   * const controlFI = new FormControl('firstname.lästname@gmail.com', ElementsValidators.emailAddress("fi"));
   * console.log(controlFI.errors); // { emailAddress: true } or null
   * ```
   */
  static emailAddress(region: Region): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      switch (region) {
        case "fi":
          return isValidFinnishEmailAddress(control.value) ? null : { emailAddress: true };
        case "se":
          return isValidSwedishEmailAddress(control.value) ? null : { emailAddress: true };
        default:
          throw Error(
            `[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'emailAddress'.`
          );
      }
    };
  }

  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid organization number based on the specified region.
   *
   * @param region Specifies the region ('se' or 'fi') for which the validation applies.
   * @returns An error map with the `organizationNumber` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('556036-2138', ElementsValidators.organizationNumber("se"));
   * console.log(control.errors); // { organizationNumber: true } or null
   * ```
   */
  static organizationNumber(region: Region): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      switch (region) {
        case "fi":
          return isValidFinnishBusinessNumber(control.value) ? null : { organizationNumber: true };
        case "se":
          return isValidSwedishBusinessNumber(control.value) ? null : { organizationNumber: true };
        default:
          throw Error(
            `[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'organizationNumber'.`
          );
      }
    };
  }

  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid personal number based on the specified region, and optionally, an age range.
   *
   * @param region Specifies the region ('se' or 'fi') for which the validation applies.
   * @param minAge (Optional) The minimum acceptable age.
   * @param maxAge (Optional) The maximum acceptable age.
   * @returns An error map with the `personalNumber` or `personalNumberAge` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const controlFI = new FormControl('220556-029L', ElementsValidators.personalNumber("fi"));
   * console.log(controlFI.errors); // { personalNumber: true } or null
   *
   * const controlSE = new FormControl('640823-3234', ElementsValidators.personalNumber("se"));
   * console.log(controlSE.errors); // { personalNumber: true } or null
   * ```
   */
  static personalNumber(region: Region, minAge?: number, maxAge?: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      switch (region) {
        case "fi":
          if (isValidFinnishPersonalNumber(control.value))
            if (isValidAgeRange(control.value, region, minAge, maxAge)) return null;
            else return { personalNumberAge: true };
          else return { personalNumber: true };
        case "se":
          if (isValidSwedishPersonalNumber(control.value))
            if (isValidAgeRange(control.value, region, minAge, maxAge)) return null;
            else return { personalNumberAge: true };
          else return { personalNumber: true };
        default:
          throw Error(
            `[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'personalNumber'.`
          );
      }
    };
  }

  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid phone number based on the specified region.
   *
   * @param region Specifies the region ('se' or 'fi') for which the validation applies.
   * @returns An error map with the `phoneNumber` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const controlFI = new FormControl('+358406361091', ElementsValidators.phoneNumber("fi"));
   * console.log(controlFI.errors); // Outputs: { phoneNumber: true } or null
   *
   * const controlSE = new FormControl('+4620-820000', ElementsValidators.phoneNumber("se"));
   * console.log(controlSE.errors); // Outputs: { phoneNumber: true } or null
   * ```
   */
  static phoneNumber(region: Region): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      switch (region) {
        case "fi":
          return isValidFinnishPhoneNumber(control.value) ? null : { phoneNumber: true };
        case "se":
          return isValidSwedishPhoneNumber(control.value) ? null : { phoneNumber: true };
        default:
          throw Error(`[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'phoneNumber'.`);
      }
    };
  }

  /**
   * Validator that checks if the control's value matches the pattern for a valid premise id in the specified region.
   *
   * @param region Specifies the region ('se') for which the validation applies.
   * @returns An error map with the `premiseId` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('735999100054384456', ElementsValidators.premiseId("se"));
   * console.log(control.errors); // Outputs: { premiseId: true } or null
   * ```
   */
  static premiseId(region: Region): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      switch (region) {
        case "se":
          return isValidSwedishPremiseId(control.value) ? null : { premiseId: true };
        default:
          throw Error(`[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'premiseId'.`);
      }
    };
  }

  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid postal code based on the specified region.
   *
   * @param region Specifies the region ('se', 'fi') for which the validation applies.
   * @param useInternationalPostalCode Specifies the region (true) for which the validation applies.
   * @returns An error map with the `postalCode` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const controlFI = new FormControl('62100', ElementsValidators.postalCode("fi"));
   * console.log(controlFI.errors); // Outputs: { postalCode: true } or null
   *
   * const controlSE = new FormControl('16956', ElementsValidators.postalCode("se"));
   * console.log(controlSE.errors); // Outputs: { postalCode: true } or null
   * ```
   */
  static postalCode(region: Region, useInternationalPostalCode?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;

      let isValidPostalCode: (value: string) => boolean;

      if (useInternationalPostalCode === true) {
        isValidPostalCode = isValidInternationalPostalCode;
      } else {
        switch (region) {
          case "fi":
            isValidPostalCode = isValidFinnishPostalCode;
            break;
          case "se":
            isValidPostalCode = isValidSwedishPostalCode;
            break;
          default:
            throw Error(
              `[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'postalCode'.`
            );
        }
      }
      return isValidPostalCode(control.value) ? null : { postalCode: true };
    };
  }

  /**
   * Validator that checks if the control's value corresponds to a weekday,
   * excluding weekends and public holidays, based on the specified region.
   *
   * @param region Specifies the region ('se' or 'fi') for which the validation applies.
   * @returns An error map with the `weekday` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const controlFI = new FormControl(new Date("2023-09-14"), ElementsValidators.weekday("fi"));
   * console.log(controlFI.errors); // Outputs: { weekday: true } or null
   *
   * const controlSE = new FormControl(new Date("2023-09-14"), ElementsValidators.weekday("se"));
   * console.log(controlSE.errors); // Outputs: { weekday: true } or null
   * ```
   */
  static weekday(region: Region): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      const value = dateFromInput(control.value);
      switch (region) {
        case "fi":
          return isValidFinnishWeekday(value) ? null : { weekday: true };
        case "se":
          return isValidSwedishWeekday(value) ? null : { weekday: true };
        default:
          throw Error(`[ Vattenfall Elements ] The provided region '${region}' is not supported by the validator 'weekday'.`);
      }
    };
  }

  /**
   * Validator that checks if the control's value is after a specified date.
   *
   * @param min Specifies the minimum date to compare with. Can be in the form of a `Date` object or string representing a date, week, month or year.
   * @returns An error map with the `minDate` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl(new Date('2023-09-15'), ElementsValidators.minDate('2023-09-01'));
   * console.log(control.errors); // Outputs: {minDate: true} or null
   * ```
   */
  static minDate(min: InputType | Date): ValidatorFn {
    const minDate = startOfDay(coerceDateProperty(min));
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      if (!isInputType(control.value)) return null;
      if (!minDate) return null;
      const date = startOfDay(coerceDateProperty(control.value));
      if (isEqual(date, minDate) || isAfter(date, minDate)) return null;
      return { minDate: true };
    };
  }

  /**
   * Validator that checks if the control's value is before a specified date.
   *
   * @param max Specifies the maximum date to compare with. Can be in the form of a `Date` object or string representing a date, week, month or year.
   * @returns An error map with the `maxDate` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl(new Date('2023-09-15'), ElementsValidators.maxDate('2023-10-01'));
   * console.log(control.errors); // Outputs: {maxDate: true} or null
   * ```
   */
  static maxDate(max: InputType | Date): ValidatorFn {
    const maxDate = startOfDay(coerceDateProperty(max));
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      if (!isInputType(control.value)) return null;
      if (!maxDate) return null;
      const date = startOfDay(coerceDateProperty(control.value));
      if (isEqual(date, maxDate) || isBefore(date, maxDate)) return null;
      return { maxDate: true };
    };
  }

  /**
   * Validator that checks if the control's value matches a valid password pattern.
   *
   * @returns An error map with the `password` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('azAZ09!@#$%^&*', ElementsValidators.password());
   * console.log(control.errors); // Outputs: {password: true} or null
   * ```
   */
  static password(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      return isValidPassword(control.value) ? null : { password: true };
    };
  }

  /**
   * Validator that checks if the control's value matches a valid grid area ID.
   *
   * @returns An error map with the `gridAreaId` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('STH', ElementsValidators.gridAreaId());
   * console.log(control.errors); // Outputs: {gridAreaId: true} or null
   * ```
   */
  static gridAreaId(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      return isValidGridAreaId(control.value) ? null : { gridAreaId: true };
    };
  }

  /**
   * Validator that checks if the control's value adheres to the expected pattern for a street name.
   *
   * @returns An error map with the `streetName` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('Main St.', ElementsValidators.streetName());
   * console.log(control.errors); // Outputs: {streetName: true} or null
   * ```
   */
  static streetName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      return isValidStreetName(control.value) ? null : { streetName: true };
    };
  }

  /**
   * Validator that checks if the control's value adheres to the expected format for a care-of address.
   *
   * @returns An error map with the `careOfAddress` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('John Doe', ElementsValidators.careOfAddress());
   * console.log(control.errors); // Outputs: {careOfAddress: true} or null
   * ```
   */
  static careOfAddress(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isValidCareOfAddress(control.value) ? null : { careOfAddress: true };
    };
  }

  /**
   * Validator that checks if the control's value adheres to the expected format for a postal town.
   *
   * @returns An error map with the `postalTown` property if validation fails; otherwise, null.
   *
   * ### Example:
   * ```typescript
   * const control = new FormControl('Örebro', ElementsValidators.postalTown());
   * console.log(control.errors); // Outputs: {postalTown: true} or null
   * ```
   */
  static postalTown(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) return null;
      return isValidSwedishFinnishPostalTown(control.value) ? null : { postalTown: true };
    };
  }
}
