import { isNullable } from "./nullify";

export type SearchParams = {
  [key: string]: string | number;
};

/**
 * Constructs a HREF from a base URL and search parameters.
 */
export function getURL(baseUrl?: string | null, searchParams?: SearchParams) {
  const url = new URL(baseUrl ?? "");
  if (searchParams)
    for (const [key, value] of Object.entries(searchParams))
      if (!isNullable(value)) url.searchParams.append(key, value.toString());
  return url.href;
}
