import { Injectable } from "@angular/core";
import { mergeDeep, uuid } from "@vattenfall/util";
import { Observable, ReplaySubject } from "rxjs";

/**
 * The key to which the global store is referenced by in the storage
 */
export const STORAGE_NAMESPACE = "@Elements";

/**
 * Extends 'window' object with Redux Web Tools
 */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION__: {
      /**
       * Connects to Redux Devtools.
       * Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
       */
      connect: (options?: Record<string, unknown>) => ReduxDevtools;
    };
  }
}

/**
 * The type describing a ReduxDevtools option
 */
export type ReduxDevtools = {
  /** Adds a change listener. It will be called any time an action is dispatched from the monitor. Returns a function to unsubscribe the current listener. */
  subscribe(message: string): void;
  /** Unsubscribes all listeners. */
  unsubscribe(): void;
  /** Sends a new action and state manually to be shown on the monitor. If action is null then we suppose we send liftedState. */
  send(action: string, state: Record<string, unknown>): void;
  /** Sends the initial state to the monitor. */
  init(state: Record<string, unknown>): void;
  /** Sends the error message to be shown in the extension's monitor. */
  error(message: string): void;
};

/**
 * The type describing Redux Devtools and Redux state object
 */
export type Redux = {
  devtools: ReduxDevtools | null;
  state: Record<string, unknown> | null;
};

/**
 * An instance of Redux Devtools (if the extention has been installed in the browser)
 */
const redux = {
  devtools:
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__.connect({ name: "Vattenfall Elements" })) || null,
  state: null,
} as Redux;

/**
 * The type describing a state action
 */
export type Action = {
  /**
   * Name of last state action
   */
  readonly action: string;
};

/**
 * The type describing a value state object
 */
export type StateModel<T> = Partial<T> & Action;

/**
 * The store is a global state manager that dispatches actions to the element state containers, sets and persists state in session storage and ensures a unidirectional flow of the application data.
 *
 * **Example**
 *
 * Sets a new state value from object
 *
 * ```typescript
 * ExampleComponent {
 *   constructor(store: Store<ExampleModel>) {
 *     setState("new_state", { isActive: true })
 *   }
 * }
 * ```
 *
 * Sets a new state value from HTTP response using callback function
 *
 * ```typescript
 * ExampleComponent {
 *   constructor(store: Store<ExampleModel>, exampleService: ExampleService) {
 *     exampleService.get(this.id)
 *       .then(store.setState("completed"))
 *       .catch(store.setState("errorred"))
 *   }
 * }
 * ```
 */
@Injectable({
  providedIn: "any",
})
export class Store<T = void> {
  /**
   * State presented as an observable stream
   */
  public get stateChanges(): Observable<StateModel<T>> {
    return this.store$.asObservable();
  }
  /**
   * A snapshot of present state
   */
  public get snapshot(): StateModel<T> {
    return Object.freeze(this.activeState) as StateModel<T>;
  }
  /**
   * Returns the current value as an javascript object in browser storage associated with the given key, or null if the given key does not exist in the list associated with the object.
   *
   * The default storage engine is `sessionStorage`. Pass in another storage engine to override this behavior.
   */
  public getStorage(key: string, storageEngine = sessionStorage): T {
    const sessionStorageItem = storageEngine.getItem(`${STORAGE_NAMESPACE}${this.storageIdentifier}${key}`);
    if (sessionStorageItem) {
      return JSON.parse(sessionStorageItem);
    } else return {} as T;
  }
  /**
   * Sets the value of the pair identified by key to value in browser storage, creating a new key/value pair if none existed for key previously.
   *
   * The default storage engine is `sessionStorage`. Pass in another storage engine to override this behavior.
   */
  public setStorage(key: string, state = {}, storageEngine = sessionStorage) {
    storageEngine.setItem(`${STORAGE_NAMESPACE}${this.storageIdentifier}${key}`, JSON.stringify(state));
  }
  /**
   * Patch the value of the pair identified by key to value in browser storage, creating a new key/value pair if none existed for key previously.
   *
   * The default storage engine is `sessionStorage`. Pass in another storage engine to override this behavior.
   */
  public patchStorage(key: string, state = {}, storageEngine = sessionStorage) {
    this.setStorage(key, { ...this.getStorage(key), ...state }, storageEngine);
  }
  /**
   * Removes the key/value pair with the given key from the list associated with the object in browser storage, if a key/value pair with the given key exists.
   *
   * The default storage engine is `sessionStorage`. Pass in another storage engine to override this behavior.
   */
  public clearStorage(key: string, storageEngine = sessionStorage) {
    storageEngine.removeItem(`${STORAGE_NAMESPACE}${this.storageIdentifier}${key}`);
  }
  /**
   * Unique identifier for this storage instance
   */
  public id = uuid();
  /**
   * If storage should use . or - between STORAGE_NAMESPACE and the KEY
   */
  public storageIdentifier = "-";
  /**
   * Store dispatcher
   */
  private store$ = new ReplaySubject<StateModel<T>>();
  /**
   * Holds a local record of the active state
   */
  private activeState = { action: "initialized" } as StateModel<T>;
  /**
   * Reset the state to a new value.
   *
   * Dispatches an action, updates the state if payload has been provided, and persists new state in storage. If no state has been provided, a callback function will returned that takes a new state object as its parameter.
   */
  public setState(action: string, state?: T) {
    const setState = (state: T) => {
      return this.setStorageAndState({ action, ...state });
    };
    if (state) return setState(state);
    return setState;
  }
  /**
   * Patch the existing state with the provided value.
   *
   * Dispatches an action, updates the state if payload has been provided, and persists new state in storage. If no state has been provided, a callback function will returned that takes a new state object as its parameter.
   */
  public patchState(action: string, state?: T) {
    const patchState = (state?: T) => {
      return this.setStorageAndState(mergeDeep<StateModel<T>>(this.activeState, { action, ...state }));
    };
    if (state) return patchState(state);
    return patchState;
  }
  /**
   * Sets a new action and resets state.
   *
   * Dispatches an action, resets the state, and persists new empty state in storage.
   */
  public setAction(action: string) {
    this.setStorageAndState({ action } as StateModel<T>);
  }
  /**
   * Sets active state, updates storage, tracking and dispatches next state
   */
  private setStorageAndState(state: StateModel<T>) {
    this.activeState = state;
    this.setStorage(this.id, state);
    this.setTracking(state, state.action);
    this.store$.next(state);
    return undefined;
  }
  /**
   * Publish state to Redux Devtools for state management tracking
   */
  private setTracking(state = {}, actionName: string) {
    if (redux.devtools) {
      if (!redux.state) {
        redux.devtools.init({});
        redux.state = {};
      }
      redux.state = Object.assign(redux.state, { [this.id]: state });
      redux.devtools.send(actionName, redux.state);
    }
  }
}
