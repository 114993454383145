/**
 * Converts a hex color code to an RGB color array.
 *
 * @param hex A 6 character hex color code e.g. #ffffff.
 * @returns An RGB color array.
 */
export function hexToRgb(hex: `#${string}`): number[] {
  if (hex.length !== 7)
    throw new TypeError(`'${hex}' code is not a valid hex code. Must be 6 characters and suffixed with '#' e.g. #ffffff.`);
  const hexCode = hex.slice(1);
  const red = parseInt(hexCode.substr(0, 2), 16);
  const green = parseInt(hexCode.substr(2, 2), 16);
  const blue = parseInt(hexCode.substr(4, 2), 16);

  return [red, green, blue];
}
