import { ɵmarkDirty as markDirty } from "@angular/core";

import { logError } from "../functions";
import { StateModel } from "../store";

/**
 * A viewstate is a visual manifestation of a component as rendered in view.
 *
 * **Example**
 *
 * ```typescript
 * {
 *   loading: {
 *     action: "loading"
 *   },
 *   completed: {
 *     action: "completed",
 *     timeTariff: 1234
 *   }
 * }
 * ```
 */
export type Viewstates<T> = Record<string, StateModel<T>>;

/**
 * Decorator that dispatches a viewstate when its bound property value has changed
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Viewstate<T>(viewstates: Viewstates<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (target: any, propertyKey: string) {
    let previousViewstate: string;
    Object.defineProperty(target, propertyKey, {
      get: () => previousViewstate,
      set: function (nextViewstate: string) {
        if (nextViewstate && previousViewstate !== nextViewstate) {
          if (!viewstates[nextViewstate])
            return logError(
              `Cannot dispatch the viewstate '${nextViewstate}' since it does not exist in '${target.constructor.name}'.`
            );
          if (!viewstates[nextViewstate].action)
            return logError(
              `Cannot dispatch the viewstate '${nextViewstate}' in '${target.constructor.name}' since it does not have an 'action' property.`
            );
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const ctx = this;
          Object.entries(viewstates[nextViewstate]).forEach(([key, value]) => {
            ctx[key] = value;
            markDirty(ctx);
          });
        }
        previousViewstate = nextViewstate;
      },
    });
  };
}
