import { DecimalPipe } from "@angular/common";
import { Inject, Optional, Pipe, PipeTransform } from "@angular/core";
import { coerceNumberProperty } from "@vattenfall/util";

import { ENVIRONMENT, Environment } from "../elements";

/**
 * List of units supported by this application
 */
const UNITS = <const>[
  "KWH",
  "MWH",
  "GWH",
  "SWEDISH_KRONA",
  "SWEDISH_ORE",
  "SWEDISH_ORE/KWH",
  "SWEDISH_KRONA/YEAR",
  "SWEDISH_KRONA/MONTH",
  "INVOICES_PER_YEAR",
  "KWH_PER_YEAR",
  "MWH_PER_YEAR",
  "SWEDISH_KRONA/MWH",
  "SWEDISH_KRONA/CUBIC_METER",
  "UNIT_UNKNOWN",
  "EURO/MONTH",
  "EURO_CENT/KWH",
  "MONTH",
  "WEEKS",
  "MONTHS",
  "DAYS",
  "YEARS",
  "KG/MONTH",
  "DAYS_REMAINING",
  "CELSIUS",
  "M3",
  "SN1",
  "SN2",
  "SN3",
  "SN4",
];

/**
 * The type describing supported unit keys for this application
 */
export type Unit = typeof UNITS[number];

/**
 * The type describing a unit value.
 *
 * A unit-value is a special kind of object that is used when consuming data from backend services
 * used by Vattenfall.
 *
 * It contains metadata of the kind of value that is being provided so that the frontend can
 * present it in a way that makes sense for the end user.
 */
export type UnitValue = {
  readonly unit: Unit;
  readonly value: number;
};

/**
 * Resolves rest parameters into key-value tuple
 * @param decimalPipe Decimal pipe transform function to format value with
 * @param parameters Parameters as passed into the pipe
 */
function resolveParameters(
  decimalPipe: DecimalPipe,
  parameters: [UnitValue, string] | [Unit] | [UnitValue, Unit, string]
): [string | null, Unit] {
  // Returns true if value is a unit-value object
  const isUnitValue = (value: string | UnitValue) => typeof value !== "string" && isUnitValueKey(value?.unit);

  // Returns true if value is a unit-value key
  const isUnitValueKey = (value: Unit) => UNITS.includes(value);

  if (parameters.length) {
    if (isUnitValue(parameters[0])) {
      const [unitValue, digitsInfo] = parameters as unknown as [UnitValue, string];
      return [decimalPipe.transform(coerceNumberProperty(unitValue.value), digitsInfo ?? null), unitValue.unit];
    } else if (isUnitValueKey(parameters[0] as Unit)) {
      const [unit] = parameters as [Unit];
      return ["", unit];
    } else if (isUnitValueKey(parameters[1] as Unit)) {
      const [value, unit, digitsInfo] = parameters as unknown as [UnitValue, Unit, string];
      return [decimalPipe.transform(coerceNumberProperty(value), digitsInfo ?? null), unit];
    }
  }

  throw new Error(
    `[ Vattenfall Elements ] Invalid pipe argument for pipe 'unit'. Expect a 'UnitValue' object, a 'Unit' or a number followed by a unit parameter. Got ${JSON.stringify(
      parameters
    )}`
  );
}

/**
 * The unit pipe will take unit-value and parse it into a string that is meaningful for the end user.
 *
 * It takes either a `UnitValue` object, a `Unit` or numeric value as input. If a numeric value then
 * a `key` parameter must be provided. Optionally, a digits parameter may be provided which parse the
 * numeric value into the specified format.
 * If only a `Unit` is provided the translated unit is returned.
 *
 * The unit pipe will produce different outputs depending on the locale of current runtime environment.
 *
 * ### Usage
 *
 * **With a unit-value object as input**
 *
 * ```html
 * input: { key: "KWH_PER_YEAR", value: 12532 } | unit
 * output: 12 523 kWh/år
 * ```
 *
 * **With a numeric value as input**
 *
 * ```html
 * input: 13.43 | unit : 'EURO/MONTH' }}
 * output: 13.43 €/kk
 * ```
 *
 * **With a digits parameter**
 *
 * ```html
 * input: 13.43 | unit : '1.1-1'
 * output: 13.4 €/kk
 * ```
 *
 * **With a `Unit` parameter**
 *
 * ```html
 * input: "KWH" | unit
 * output: kWh
 * ```
 */
@Pipe({
  name: "unit",
})
export class UnitPipe implements PipeTransform {
  constructor(
    @Optional()
    @Inject(ENVIRONMENT)
    private environment: Environment,
    private decimalPipe: DecimalPipe
  ) {}
  /**
   * @param input The unit-value object, `Unit` or number to be formatted.
   * @param unit A unit key used for parsing the value. Required if the provided input is a number.
   * @param digitsInfo Decimal representation options, specified by a string
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `0`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `3`.
   */
  transform(...parameters: Array<unknown>): string | null {
    if (!parameters.length || parameters[0] == null) return null;

    const [value, unit] = resolveParameters(
      this.decimalPipe,
      parameters as unknown as [UnitValue, string] | [UnitValue, Unit, string]
    );

    switch (this.environment.language) {
      case "sv": {
        return this.sv(value, unit);
      }
      case "fi": {
        return this.fi(value, unit);
      }
      default:
        throw new Error(
          `[ Vattenfall Elements ] Invalid pipe argument for pipe 'unit'. The locale '${this.environment.locale}' is not supported by this pipe.`
        );
    }
  }

  private sv(value: string | null, unit: Unit) {
    switch (unit) {
      case "KWH":
        return `${value} kWh`;
      case "MWH":
        return `${value} MWh`;
      case "GWH":
        return `${value} GWh`;
      case "SWEDISH_KRONA":
        return `${value} kr`;
      case "SWEDISH_ORE":
        return `${value} öre`;
      case "SWEDISH_ORE/KWH":
        return `${value} öre/kWh`;
      case "SWEDISH_KRONA/YEAR":
        return `${value} kr/år`;
      case "SWEDISH_KRONA/MONTH":
        return `${value} kr/mån`;
      case "INVOICES_PER_YEAR":
        return Number(value) > 1 ? `${value} fakturor per år` : `${value} faktura per år`;
      case "KWH_PER_YEAR":
        return `${value} kWh/år`;
      case "MWH_PER_YEAR":
        return `${value} MWh/år`;
      case "SWEDISH_KRONA/MWH":
        return `${value} kr/MWh`;
      case "SWEDISH_KRONA/CUBIC_METER":
        return `${value} kr/m<sup>3</sup>`;
      case "UNIT_UNKNOWN":
        return `${value}`;
      case "EURO/MONTH":
        return `${value} €/mån`;
      case "EURO_CENT/KWH":
        return `${value} c/kWh`;
      case "MONTH":
        return `${value} månad`;
      case "MONTHS":
        return Number(value) > 1 ? `${value} månader` : `${value} månad`;
      case "WEEKS":
        return Number(value) > 1 ? `${value} veckor` : `${value} vecka`;
      case "DAYS":
        return Number(value) > 1 ? `${value} dagar` : `${value} dag`;
      case "YEARS":
        return `${value} år`;
      case "KG/MONTH":
        return `${value} kg/mån`;
      case "DAYS_REMAINING":
        return Number(value) === 0 ? `idag` : Number(value) === 1 ? `${value} dag kvar` : `${value} dagar kvar`;
      case "CELSIUS":
        return `${value} °C`;
      case "M3":
        return `${value ? value : ""} m<sup>3</sup>`.trim();
      case "SN1":
        return "Elområde 1";
      case "SN2":
        return "Elområde 2";
      case "SN3":
        return "Elområde 3";
      case "SN4":
        return "Elområde 4";
      default:
        throw new Error(
          `[ Vattenfall Elements ] Invalid pipe argument for pipe 'unit'. The unit '${unit}' is not supported for this locale ${this.environment.locale}.`
        );
    }
  }
  private fi(value: string | null, unit: Unit) {
    switch (unit) {
      case "KWH":
        return `${value} kWh`;
      case "MWH":
        return `${value} MWh`;
      case "GWH":
        return `${value} GWh`;
      case "UNIT_UNKNOWN":
        return `${value}`;
      case "EURO/MONTH":
        return `${value} €/kk`;
      case "EURO_CENT/KWH":
        return `${value} snt/kWh`;
      case "MONTH":
        return `${value} kk`;
      case "MONTHS":
        return `${value} kk`;
      case "KG/MONTH":
        return `${value} kg/kk`;
      default:
        throw new Error(
          `[ Vattenfall Elements ] Invalid pipe argument for pipe 'unit'. The unit '${unit}' is not supported for this locale ${this.environment.locale}.`
        );
    }
  }
}
