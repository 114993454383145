import { Injectable } from "@angular/core";

/**
 * Creates a blob file from string and provides it as a downloadable object.
 *
 * Requires that a file MIME-type is specified, e.g. `text/csv` or `application/json`.
 */
@Injectable({
  providedIn: "root",
})
export class FileDownloadService {
  /**
   * Creates a file blob, downloads it and deletes it from memory.
   * @param fileContent File content as string
   * @param type File MIME-type, e.g. text/csv
   * @param fileName Name of file. If omitted a unique hash will be used.
   */
  download(fileContent: string, type: string, fileName?: string) {
    // Create temporary anchor tag
    const anchorElement = document.createElement("a");
    // We use `\ufeff` to signify any number of encoding.
    // It takes the `\ufeff` marker and converts it into the correct byte order mark based on charset
    const blob = new Blob(["\ufeff", fileContent], { type });
    // Create DOMString containing url represented as a blob
    const href = window.URL.createObjectURL(blob);
    // Register attribute with URL and file name
    anchorElement.href = href;
    if (fileName) anchorElement.download = fileName;
    anchorElement.click();
    // Revoking downloadable object let the browser know not to keep the reference to the file any longer.
    window.URL.revokeObjectURL(href);
    // Remove temporary anchor tag from document
    anchorElement.remove();
  }
}
