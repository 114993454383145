/**
 * Convert a kebab-cased string to camelCased.
 */
export function kebabToCamelCase(input: string): string {
  return input.replace(/-([a-z\d])/g, (_, char) => char.toUpperCase());
}

/**
 * Convert a kebab-cased string to snake_cased.
 */
export function kebabToSnakeCase(input: string): string {
  return input.replace(/-([a-z\d])/g, (_, char) => `_${char.toLowerCase()}`);
}

/**
 * Convert a camelCased string to kebab-cased.
 */
export function camelToDashCase(input: string): string {
  return input.replace(/[A-Z]/g, (char) => `-${char.toLowerCase()}`);
}
