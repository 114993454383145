/* eslint-disable @angular-eslint/directive-class-suffix */

import { Directive, Inject, Input, OnChanges, Optional, SimpleChanges } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";
import {
  BooleanInput,
  coerceBooleanProperty,
  coerceDateProperty,
  coerceNumberProperty,
  InputDate,
  InputMonth,
  InputWeek,
  InputYear,
  NumberInput,
} from "@vattenfall/util";

import { Environment, ENVIRONMENT } from "../elements";

import { ElementsValidators } from "./validators";

/**
 * A base class for Validator-based Directives. The class contains common logic shared across such
 * Directives.
 *
 * For internal use only, this class is not intended for use outside of the Forms package.
 */
@Directive()
abstract class AbstractValidatorDirective implements Validator, OnChanges {
  private _validator: ValidatorFn = Validators.nullValidator;
  private _onChange!: () => void;

  /**
   * A flag that tracks whether this validator is enabled.
   *
   * Marking it `internal` (vs `protected`), so that this flag can be used in host bindings of
   * directive classes that extend this base class.
   */
  _enabled?: boolean;

  /**
   * Name of an input that matches directive selector attribute (e.g. `minlength` for
   * `MinLengthDirective`). An input with a given name might contain configuration information (like
   * `minlength='10'`) or a flag that indicates whether validator should be enabled (like
   * `[required]='false'`).
   */
  abstract inputName: string;

  /**
   * Creates an instance of a validator (specific to a directive that extends this base class).
   */
  abstract createValidator(input: unknown): ValidatorFn;

  /**
   * Performs the necessary input normalization based on a specific logic of a Directive.
   * For example, the function might be used to convert string-based representation of the
   * `minlength` input to an integer value that can later be used in the `Validators.minLength`
   * validator.
   */
  abstract normalizeInput(input: unknown): unknown;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputName in changes) {
      const input = this.normalizeInput(changes[this.inputName].currentValue);
      this._enabled = this.enabled(input);
      this._validator = this._enabled ? this.createValidator(input) : Validators.nullValidator;
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this._validator(control);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  /**
   * Determines whether this validator should be active or not based on an input.
   * Base class implementation checks whether an input is defined (if the value is different from
   * `null` and `undefined`). Validator classes that extend this base class can override this
   * function with the logic specific to a particular validator directive.
   */
  enabled(input: unknown): boolean {
    return input != null /* both `null` and `undefined` */;
  }
}

/**
 * A directive that adds the `min` validator to controls marked with the
 * `min` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=number][min][formControlName],[type=number][min][formControl],[type=number][min][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidator, multi: true }],
  host: { "[attr.min]": "_enabled ? min : null" },
})
export class MinValidator extends AbstractValidatorDirective {
  /**
   * The smallest number to accept for `value`.
   *
   * ```html
   * <input type="number" min="1" ngModel />
   * <input type="number" [min]="1" ngModel />
   * ```
   */
  @Input() min!: NumberInput;

  override inputName = "min";
  override normalizeInput = coerceNumberProperty;
  override createValidator = Validators.min;
}

/**
 * A directive that adds the `min` validator to controls marked with the
 * `min` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=date][min][formControlName],[type=date][min][formControl],[type=date][min][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDateValidator, multi: true }],
  host: { "[attr.min]": "_enabled ? min : null" },
})
export class MinDateValidator extends AbstractValidatorDirective {
  /**
   * The earliest date to accept for `value`.
   *
   * ```html
   * <input type="date" min="2023-09-15" ngModel />
   * <input type="date" [min]="new Date('2023-09-15')" ngModel />
   * ```
   */
  @Input() min!: InputDate | Date;

  override inputName = "min";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.minDate;
}

/**
 * A directive that adds the `min` validator to controls marked with the
 * `min` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=week][min][formControlName],[type=week][min][formControl],[type=week][min][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinWeekValidator, multi: true }],
  host: { "[attr.min]": "_enabled ? min : null" },
})
export class MinWeekValidator extends AbstractValidatorDirective {
  /**
   * The earliest week to accept for `value`.
   *
   * ```html
   * <input type="week" min="2023-W09" ngModel />
   * <input type="week" [min]="new Date('2023-09-15')" ngModel />
   * ```
   */
  @Input() min!: InputWeek | Date;

  override inputName = "min";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.minDate;
}

/**
 * A directive that adds the `min` validator to controls marked with the
 * `min` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=month][min][formControlName],[type=month][min][formControl],[type=month][min][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMonthValidator, multi: true }],
  host: { "[attr.min]": "_enabled ? min : null" },
})
export class MinMonthValidator extends AbstractValidatorDirective {
  /**
   * The earliest month to accept for `value`.
   *
   * ```html
   * <input type="month" min="2023-09" ngModel />
   * <input type="month" [min]="new Date(2023, 8)" ngModel />
   * ```
   */
  @Input() min!: InputMonth | Date;

  override inputName = "min";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.minDate;
}

/**
 * A directive that adds the `min` validator to controls marked with the
 * `min` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=year][min][formControlName],[type=year][min][formControl],[type=year][min][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinYearValidator, multi: true }],
  host: { "[attr.min]": "_enabled ? min : null" },
})
export class MinYearValidator extends AbstractValidatorDirective {
  /**
   * The earliest year to accept for `value`.
   *
   * ```html
   * <input type="year" min="2023" ngModel />
   * <input type="year" [min]="new Date(2023, 8)" ngModel />
   * ```
   */
  @Input() min!: InputYear | Date;

  override inputName = "min";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.minDate;
}

/**
 * A directive that adds the `max` validator to controls marked with the
 * `max` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=number][max][formControlName],[type=number][max][formControl],[type=number][max][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true }],
  host: { "[attr.max]": "_enabled ? max : null" },
})
export class MaxValidator extends AbstractValidatorDirective {
  /**
   * The smallest number to accept for `value`.
   *
   * ```html
   * <input type="number" max="1" ngModel />
   * <input type="number" [max]="1" ngModel />
   * ```
   */
  @Input() max!: NumberInput;

  override inputName = "max";
  override normalizeInput = coerceNumberProperty;
  override createValidator = Validators.max;
}

/**
 * A directive that adds the `max` validator to controls marked with the
 * `max` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=date][max][formControlName],[type=date][max][formControl],[type=date][max][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxDateValidator, multi: true }],
  host: { "[attr.max]": "_enabled ? max : null" },
})
export class MaxDateValidator extends AbstractValidatorDirective {
  /**
   * The latest date to accept for `value`.
   *
   * ```html
   * <input type="date" max="2023-09-15" ngModel />
   * <input type="date" [max]="new Date('2023-09-15')" ngModel />
   * ```
   */
  @Input() max!: InputDate | Date;

  override inputName = "max";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.maxDate;
}

/**
 * A directive that adds the `max` validator to controls marked with the
 * `max` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=week][max][formControlName],[type=week][max][formControl],[type=week][max][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxWeekValidator, multi: true }],
  host: { "[attr.max]": "_enabled ? max : null" },
})
export class MaxWeekValidator extends AbstractValidatorDirective {
  /**
   * The latest week to accept for `value`.
   *
   * ```html
   * <input type="week" max="2023-W09" ngModel />
   * <input type="week" [max]="new Date('2023-09-15')" ngModel />
   * ```
   */
  @Input() max!: InputWeek | Date;

  override inputName = "max";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.maxDate;
}

/**
 * A directive that adds the `max` validator to controls marked with the
 * `max` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=month][max][formControlName],[type=month][max][formControl],[type=month][max][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxMonthValidator, multi: true }],
  host: { "[attr.max]": "_enabled ? max : null" },
})
export class MaxMonthValidator extends AbstractValidatorDirective {
  /**
   * The latest month to accept for `value`.
   *
   * ```html
   * <input type="month" max="2023-09" ngModel />
   * <input type="month" [max]="new Date(2023, 08)" ngModel />
   * ```
   */
  @Input() max!: InputMonth | Date;

  override inputName = "max";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.maxDate;
}

/**
 * A directive that adds the `max` validator to controls marked with the
 * `max` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=year][max][formControlName],[type=year][max][formControl],[type=year][max][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxYearValidator, multi: true }],
  host: { "[attr.max]": "_enabled ? max : null" },
})
export class MaxYearValidator extends AbstractValidatorDirective {
  /**
   * The latest year to accept for `value`.
   *
   * ```html
   * <input type="year" max="2023" ngModel />
   * <input type="year" [max]="new Date(2023, 08)" ngModel />
   * ```
   */
  @Input() max!: InputYear | Date;

  override inputName = "max";
  override normalizeInput = coerceDateProperty;
  override createValidator = ElementsValidators.maxDate;
}

/**
 * A directive that adds the `weekday` validator to controls marked with the
 * `weekday` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[type=date][weekday][formControlName],[type=date][weekday][formControl],[type=date][weekday][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: WeekdayValidator, multi: true }],
  host: { "[attr.weekday]": "_enabled ? weekday : null" },
})
export class WeekdayValidator extends AbstractValidatorDirective implements OnChanges {
  /**
   * Validator that checks if the control's value matches a date represented as a string
   * that is not a weekend and/or a public holiday determent on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add an weekday validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="date" weekday ngModel />
   * <input type="date" weekday="true" ngModel />
   * <input type="date" [weekday]="true" ngModel />
   * ```
   */
  @Input() weekday!: BooleanInput;

  override inputName = "weekday";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = () => ElementsValidators.weekday(this.environment.region);
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `emailAddress` validator to controls marked with the
 * `emailAddress` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[emailAddress][formControlName],[emailAddress][formControl],[emailAddress][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailAddressValidator, multi: true }],
  host: { "[attr.emailAddress]": "_enabled ? emailAddress : null" },
})
export class EmailAddressValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid email address based on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add an email address validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" emailAddress ngModel />
   * <input type="text" emailAddress="true" ngModel />
   * <input type="text" [emailAddress]="true" ngModel />
   * ```
   */
  @Input() emailAddress!: BooleanInput;

  override inputName = "emailAddress";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn => ElementsValidators.emailAddress(this.environment.region);
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `organizationNumber` validator to controls marked with the
 * `organizationNumber` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[organizationNumber][formControlName],[organizationNumber][formControl],[organizationNumber][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: OrganizationNumberValidator, multi: true }],
  host: { "[attr.organizationNumber]": "_enabled ? organizationNumber : null" },
})
export class OrganizationNumberValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid organization number based on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add an organization number validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" organizationNumber ngModel />
   * <input type="text" organizationNumber="true" ngModel />
   * <input type="text" [organizationNumber]="true" ngModel />
   * ```
   */
  @Input() organizationNumber!: BooleanInput;

  override inputName = "organizationNumber";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn => ElementsValidators.organizationNumber(this.environment.region);
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `personalNumber` validator to controls marked with the
 * `personalNumber` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[personalNumber][formControlName],[personalNumber][formControl],[personalNumber][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PersonalNumberValidator, multi: true }],
  host: { "[attr.personalNumber]": "_enabled ? personalNumber : null" },
})
export class PersonalNumberValidator extends AbstractValidatorDirective implements OnChanges {
  /**
   * Validator that checks if the control's value matches the pattern and algorithm
   * for a valid personal number based on the `ENVIRONMENT` injection token region, and optionally, an age range.
   *
   * The following example shows how to add an personal number validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" personalNumber ngModel />
   * <input type="text" personalNumber="true" ngModel />
   * <input type="text" [personalNumber]="true" ngModel />
   * ```
   * The following example shows how to specify an age range in addition to the personal number validation.
   *
   * ```html
   * <input type="text" personalNumber minAge="18" maxAge="100" ngModel />
   * ```
   */
  @Input() personalNumber!: BooleanInput;

  /**
   * Specifies the minimum age for personal number validation.
   */
  @Input() minAge?: NumberInput;

  /**
   * Specifies the maximum age for personal number validation.
   */
  @Input() maxAge?: NumberInput;

  override inputName = "personalNumber";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn =>
    ElementsValidators.personalNumber(
      this.environment.region,
      this.minAge ? coerceNumberProperty(this.minAge) : undefined,
      this.maxAge ? coerceNumberProperty(this.maxAge) : undefined
    );
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `phoneNumber` validator to controls marked with the
 * `phoneNumber` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[phoneNumber][formControlName],[phoneNumber][formControl],[phoneNumber][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneNumberValidator, multi: true }],
  host: { "[attr.phoneNumber]": "_enabled ? phoneNumber : null" },
})
export class PhoneNumberValidator extends AbstractValidatorDirective implements OnChanges {
  /**
   * Validator that checks if the control's value matches the pattern for a valid phone number based
   * on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add an phone number validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="tel" phoneNumber ngModel />
   * <input type="tel" phoneNumber="true" ngModel />
   * <input type="tel" [phoneNumber]="true" ngModel />
   * ```
   */
  @Input() phoneNumber!: BooleanInput;

  override inputName = "phoneNumber";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn => ElementsValidators.phoneNumber(this.environment.region);
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `premiseId` validator to controls marked with the
 * `premiseId` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[premiseId][formControlName],[premiseId][formControl],[premiseId][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PremiseIdValidator, multi: true }],
  host: { "[attr.premiseId]": "_enabled ? premiseId : null" },
})
export class PremiseIdValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid premise identifier based
   * on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add an organization number validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" premiseId ngModel />
   * <input type="text" premiseId="true" ngModel />
   * <input type="text" [premiseId]="true" ngModel />
   * ```
   */
  @Input() premiseId!: string | boolean;

  override inputName = "premiseId";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn => ElementsValidators.premiseId(this.environment.region);
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `postalCode` validator to controls marked with the
 * `postalCode` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
  selector: "[postalCode][formControlName],[postalCode][formControl],[postalCode][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PostalCodeValidator, multi: true }],
  host: { "[attr.postalCode]": "_enabled ? postalCode : null" },
})
export class PostalCodeValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid postal code based
   * on the `ENVIRONMENT` injection token region.
   *
   * The following example shows how to add a postal code validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" postalCode ngModel />
   * <input type="text" postalCode="true" ngModel />
   * <input type="text" [postalCode]="true" ngModel />
   * ```
   */
  @Input() postalCode!: BooleanInput;
  /**
   * Specifies the international region regex for postal code validation.
   */
  @Input() internationalRegion?: BooleanInput;

  override inputName = "postalCode";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = (): ValidatorFn =>
    ElementsValidators.postalCode(
      this.environment.region,
      this.internationalRegion ? coerceBooleanProperty(this.internationalRegion) : undefined
    );
  override enabled(input: boolean): boolean {
    return input;
  }

  constructor(@Optional() @Inject(ENVIRONMENT) private environment: Environment) {
    super();
  }
}

/**
 * A directive that adds the `password` validator to controls marked with the
 * `password` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 *
 * @todo Should support multi-regions
 */
@Directive({
  selector: "[password][formControlName],[password][formControl],[password][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }],
  host: { "[attr.password]": "_enabled ? password : null" },
})
export class PasswordValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid password.
   *
   * The following example shows how to add a password validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="password" password ngModel />
   * <input type="password" password="true" ngModel />
   * <input type="password" [password]="true" ngModel />
   * ```
   */
  @Input() password!: BooleanInput;

  override inputName = "password";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = ElementsValidators.password;
  override enabled(input: boolean): boolean {
    return input;
  }
}

/**
 * A directive that adds the `gridAreaId` validator to controls marked with the
 * `gridAreaId` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 *
 * @todo Should support multi-regions
 */
@Directive({
  selector: "[gridAreaId][formControlName],[gridAreaId][formControl],[gridAreaId][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: GridAreaIdValidator, multi: true }],
  host: { "[attr.gridAreaId]": "_enabled ? gridAreaId : null" },
})
export class GridAreaIdValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid grid area identifier.
   *
   * The following example shows how to add a grid area identifier validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" gridAreaId ngModel />
   * <input type="text" gridAreaId="true" ngModel />
   * <input type="text" [gridAreaId]="true" ngModel />
   * ```
   */
  @Input() gridAreaId!: BooleanInput;

  override inputName = "gridAreaId";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = ElementsValidators.gridAreaId;
  override enabled(input: boolean): boolean {
    return input;
  }
}

/**
 * A directive that adds the `streetName` validator to controls marked with the
 * `streetName` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 *
 * @todo Should support multi-regions
 */
@Directive({
  selector: "[streetName][formControlName],[streetName][formControl],[streetName][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: StreetNameValidator, multi: true }],
  host: { "[attr.streetName]": "_enabled ? streetName : null" },
})
export class StreetNameValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid street name.
   *
   * The following example shows how to add a street name validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" streetName ngModel />
   * <input type="text" streetName="true" ngModel />
   * <input type="text" [streetName]="true" ngModel />
   * ```
   */
  @Input() streetName!: BooleanInput;

  override inputName = "streetName";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = ElementsValidators.streetName;
  override enabled(input: boolean): boolean {
    return input;
  }
}

/**
 * A directive that adds the `careOfAddress` validator to controls marked with the
 * `careOfAddress` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 *
 * @todo Should support multi-regions
 */
@Directive({
  selector: "[careOfAddress][formControlName],[careOfAddress][formControl],[careOfAddress][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: CareOfAddressValidator, multi: true }],
  host: { "[attr.careOfAddress]": "_enabled ? careOfAddress : null" },
})
export class CareOfAddressValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid care of address.
   *
   * The following example shows how to add a care of address validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" careOfAddress ngModel />
   * <input type="text" careOfAddress="true" ngModel />
   * <input type="text" [careOfAddress]="true" ngModel />
   * ```
   */
  @Input() careOfAddress!: BooleanInput;

  override inputName = "careOfAddress";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = ElementsValidators.careOfAddress;
  override enabled(input: boolean): boolean {
    return input;
  }
}

/**
 * A directive that adds the `postalTown` validator to controls marked with the
 * `postalTown` attribute. The directive is provided with the `NG_VALIDATORS` multi-provider list.
 *
 * @todo Should support multi-regions
 */
@Directive({
  selector: "[postalTown][formControlName],[postalTown][formControl],[postalTown][ngModel]",
  providers: [{ provide: NG_VALIDATORS, useExisting: PostalTownValidator, multi: true }],
  host: { "[attr.postalTown]": "_enabled ? postalTown : null" },
})
export class PostalTownValidator extends AbstractValidatorDirective {
  /**
   * Validator that checks if the control's value matches the pattern for a valid postal town.
   *
   * The following example shows how to add a postal town validator to an input attached to an ngModel
   * binding.
   *
   * ```html
   * <input type="text" postalTown ngModel />
   * <input type="text" postalTown="true" ngModel />
   * <input type="text" [postalTown]="true" ngModel />
   * ```
   */
  @Input() postalTown!: BooleanInput;

  override inputName = "postalTown";
  override normalizeInput = coerceBooleanProperty;
  override createValidator = ElementsValidators.postalTown;
  override enabled(input: boolean): boolean {
    return input;
  }
}
