/**
 * Type describing the allowed values for a boolean input.
 */
export type BooleanInput = string | boolean | null | undefined;

/**
 * Coerces a data-bound value (typically a string) to a boolean.
 *
 * This is useful when declaring component inputs as standard HTML properties such as `disabled`,
 * where `<input disabled>` should resolve to `true` and not `undefined`, and for example `<input disabled="false">`
 * should resolve to `false` and not `"false"`.
 */
export function coerceBooleanProperty(value: unknown): boolean {
  return value != null && `${value}` !== "false";
}
