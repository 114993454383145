/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ɵmarkDirty as markDirty } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Store } from "./store.service";

/**
 * The type describing a component's class constructor
 */
type ComponentConstructor<T = any> = new (...args: any[]) => T;

/**
 * `State` is a mixin function that unwraps observable properties from store and defines them as immutable properties on the child class.
 *
 * **Example**
 *
 * ```typescript
 * ExampleComponent extends State(ExampleStateModel) {
 *   constructor(store: Store) {
 *     super()
 *     super.setStore(store)
 *   }
 * }`
 * ```
 * @param StateModel State model class
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function State<T extends ComponentConstructor>(StateModel: T) {
  @Directive()
  class State extends StateModel {
    constructor(...args: any[]) {
      super(args);
    }

    /**
     * Attaches the `Store` to state, subscribes to state changes, checks for properties on the child component with matching keys and updates the value of those properties.
     * @param store The instance of `Store` to subscribe to
     */
    public setStore<T>(store: Store<T>) {
      const destroyed$ = new Subject<void>();
      const onDestroy = this.constructor.prototype.ngOnDestroy || (() => {});
      // Unwraps properties from state observable and sets values on component
      store.stateChanges.pipe(takeUntil(destroyed$)).subscribe((state) => {
        Object.entries(state).forEach(([key, value]) => {
          this[key] = value;
          markDirty(this);
        });
      });
      // Unsubscribes state subscription when the component is destroyed
      this.constructor.prototype.ngOnDestroy = function () {
        destroyed$.next();
        destroyed$.complete();
        onDestroy.call(this);
      };
    }
  }

  return State;
}
