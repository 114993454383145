/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { allPass, last, gte, lte, __ } from "ramda";

/**
 * Splits an array of numbers into a list of objects that declares the index start and end position for elements that match a range qualifier object.
 */
export function getRangeIndexes(
  numbers: Array<number>,
  rangeQualifier: {
    gte?: number;
    lte?: number;
  }
) {
  return numbers.reduce<Array<{ start: number; end: number; _currentValue?: number }>>(
    (rangeIndexes, _currentValue, currentIndex) => {
      const lastRangeIndexes = last(rangeIndexes);
      const rangeQualifierFunction = allPass([
        gte(__, rangeQualifier.gte ?? Number.NEGATIVE_INFINITY),
        lte(__, rangeQualifier.lte ?? Number.POSITIVE_INFINITY),
      ]);
      if (rangeQualifierFunction(_currentValue))
        if (rangeQualifierFunction(lastRangeIndexes?._currentValue as number)) lastRangeIndexes!.end = currentIndex + 1;
        else {
          rangeIndexes.push({
            start: currentIndex,
            end: currentIndex,
            _currentValue,
          });
        }
      else delete lastRangeIndexes?._currentValue;
      return rangeIndexes;
    },
    []
  );
}
