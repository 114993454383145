import { all, allPass, always, anyPass, ifElse, is, isEmpty, isNil, map, pipe, values, when } from "ramda";

import { evolveAny } from "./evolve-any";

/**
 * Returns `true` if the value is an empty object, empty array, empty string, undefined or null.
 */
export const isNullable = anyPass([isEmpty, isNil]);

/**
 * Returns `true` if all values of an object or array are nullable.
 */
export const isNullableObject = allPass([is(Object), pipe(values, all(isNullable))]);

/**
 * Updates all nullable values in an object to `null`.
 *
 * **Input**
 *
 * ```
 * {
 *   aaa: undefined,
 *   bbb: "",
 *   ccc: {},
 *   ddd: [],
 *   eee: null,
 *   fff: {
 *     ggg: ""
 *   },
 *   hhh: 111
 * }
 * ```
 * **Output**
 *
 * ```
 * {
 *   aaa: null,
 *   bbb: null,
 *   ccc: null,
 *   ddd: null,
 *   eee: null,
 *   fff: null,
 *   hhh: 111
 * }
 * ```
 */
export function nullify(value: unknown): Record<string, unknown> | Array<unknown> | null {
  return pipe(
    // @ts-ignore Overriding typechecker because issues in Ramda typings package that we are not maintaining. We are getting issues only when building using NPM package manager.
    when(isNullable, always(null)),
    // @ts-ignore Overriding typechecker because issues in Ramda typings package that we are not maintaining. We are getting issues only when building using NPM package manager.
    when(is(Object), ifElse(is(Array), map(nullify), evolveAny(nullify))),
    // @ts-ignore Overriding typechecker because issues in Ramda typings package that we are not maintaining. We are getting issues only when building using NPM package manager.
    when(isNullableObject, always(null))
  )(value) as unknown as Record<string, unknown> | Array<unknown> | null;
}
