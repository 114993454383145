/**
 * Type describing the allowed values for a number input
 */
export type NumberInput = string | number | null | undefined;

/**
 * Coerces a data-bound value (typically a string) to a number.
 */
export function coerceNumberProperty(value: unknown): number {
  if (!isNaN(parseFloat(value as string)) && !isNaN(Number(value))) return Number(value);
  else return NaN;
}
